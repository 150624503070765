import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../dto/common.dto';
import { IContract, IContractCreate, IContractSearch, IContractUpdate } from '../dto/contracts.dto';

export class ContractsService {
    constructor(private api: ApiClient){}

    public async searchContracts(search?: IContractSearch){
        return this.api.get<SearchResult<IContract>>('contracts', search);
    }

    public async createContract(payload: IContractCreate){
        return this.api.post<IContract>('contracts', payload);
    }

    public async updateContract(id: string, payload: IContractUpdate){
        return this.api.patch<IContract>('contracts/' + id, payload);
    }

    public async deleteContract(id: string){
        return this.api.delete<void>('contracts/' + id);
    }
}

export default new ContractsService(apiClient);