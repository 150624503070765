import { useSearchParams } from 'react-router-dom'

function getSearchParams(params: URLSearchParams){
    return params.toString() && params.toString().split('&').map(param => param.split('=')).reduce((obj, [ key, val ]) => ({ ...obj, [key]: val }), {});
}

const useSearchState = (key: string, defaultValue = ''): [string, (value: string) => void] => {
    const [params, setParams] = useSearchParams();
    const value = params.get(key) || defaultValue;
    const setValue = (value: string) => setParams({ ...getSearchParams(params), [key]: value });
    
    return [ value, setValue ]
}

export default useSearchState