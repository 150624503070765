import { useEffect, useMemo, useState } from 'react';
import { IEmployeePosition } from '../../API/dto/employeePositions.dto';
import { IContractService } from '../../API/dto/contractServices.dto';
import { ITracker } from '../../API/dto/tracker.dto';
import { Breadcrumbs, Container, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import positionsService from '../../API/services/positions.service';
import trackerService from '../../API/services/tracker.service';
import contractServicesService from '../../API/services/contractServices.service';
import dayjs from 'dayjs';
import { dateFormat } from '../../utils/dateFormat';
import useSearchState from '../../hooks/useSearchState';
import SearchDate from '../SearchDate';

const borderLeft = '1px solid rgba(224, 224, 224, 1)';

interface IPaymentsValues {
    amount: number,
    summ: number
}

function zeroValues():IPaymentsValues {
    return { amount: 0, summ: 0 }
}


const PositionPayments = () => {
    const [dateFrom, setDateFrom] = useSearchState('dateFrom', dayjs().subtract(1, 'week').format(dateFormat));
    const [dateTo, setDateTo] = useSearchState('dateTo', dayjs().format(dateFormat));
    const [positions, setPositions] = useState<IEmployeePosition[]>();
    const [trackers, setTrackers] = useState<ITracker[]>();
    const [contractServices, setContractServices] = useState<IContractService[]>();

    useEffect(() => {
        Promise.all([
            positionsService.search(),
            trackerService.searchTrackers({ dateFrom, dateTo }),
            contractServicesService.search()
        ]).then(([positions, trackers, contractServices]) => {
            setPositions(positions.items);
            setTrackers(trackers.items);
            setContractServices(contractServices.items);
        }).catch(console.log)
    }, [dateTo, dateFrom])

    const { positionsValues, totalValues } = useMemo(() => {
        const serviceCostForCounterparty = new Map<string, number>();
        contractServices?.forEach(cs => serviceCostForCounterparty.set(cs.serviceId, cs.costForCounterparty));

        let values: IPaymentsValues;
        const positionsValues = new Map<string, IPaymentsValues>();
        const totalValues = zeroValues();

        trackers?.forEach(tracker => {
            if(tracker.employee.positionId){
                values = positionsValues.get(tracker.employee.positionId) || zeroValues();
                values.amount += tracker.amount;
                totalValues.amount += tracker.amount;
                const cost = serviceCostForCounterparty.get(tracker.serviceId) || 0;
                values.summ += cost * tracker.amount;
                totalValues.summ += cost * tracker.amount;
                positionsValues.set(tracker.employee.positionId, values);
            }
        })

        return { positionsValues, totalValues }
    }, [trackers, contractServices])

    if(!positions) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500  }}/>
        </Container>
    )
    
    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Счет-акт</Typography>
            </Breadcrumbs>

            <Stack direction='row' gap='10px' flexWrap='wrap' marginTop='40px'>
                <SearchDate date={dateFrom} setDate={setDateFrom}/>
                <SearchDate date={dateTo} setDate={setDateTo}/>
            </Stack>

            <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                <Typography fontSize={18} fontWeight="600">Должность</Typography>
                            </TableCell>
                            <TableCell align="center" sx={{ borderLeft }}>
                                <Typography fontSize={18} fontWeight="600">Количество, ч</Typography>
                            </TableCell>
                            <TableCell align="center" sx={{ borderLeft }}>
                                <Typography fontSize={18} fontWeight="600">Стоимость, р&nbsp;/&nbsp;ч</Typography>
                            </TableCell>
                            <TableCell align="center" sx={{ borderLeft }}>
                                <Typography fontSize={18} fontWeight="600">Сумма, р</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            positions.map(position => {
                                const { amount, summ } = positionsValues.get(position.id) || zeroValues();
                                return (
                                    <TableRow key={position.id}>
                                        <TableCell align="center">
                                            <Typography fontSize={16}>{position.name}</Typography>
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderLeft }}>
                                            <Typography fontSize={16}>{amount}</Typography>
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderLeft }}>
                                            <Typography fontSize={16}>{amount ? (summ / amount).toFixed(2) : 0}</Typography>  
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderLeft }}>
                                            <Typography fontSize={16}>{summ}</Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                        <TableRow sx={{ fontWeight: 'bold' }}>
                            <TableCell align="center">
                                <Typography fontSize={18} fontWeight="600">Итого</Typography>
                            </TableCell>
                            <TableCell align="center" sx={{ borderLeft }}>
                                <Typography fontSize={18} fontWeight="600">{totalValues.amount}</Typography>
                            </TableCell>
                            <TableCell align="center" sx={{ borderLeft }}>
                                <Typography fontSize={18} fontWeight="600">{totalValues.amount ? (totalValues.summ / totalValues.amount).toFixed(2) : 0}</Typography>
                            </TableCell>
                            <TableCell align="center" sx={{ borderLeft }}>
                                <Typography fontSize={18} fontWeight="600">{totalValues.summ}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}

export default PositionPayments;