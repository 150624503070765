import { FC, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import EmployeePositionsNew from './EmployeePositionsNew';
import EmployeePositionsItem from './EmployeePositionsItem';
import { IEmployeePosition, IEmployeePositionCreate, IEmployeePositionUpdate } from '../../API/dto/employeePositions.dto';
import positionsService from '../../API/services/positions.service';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import usePermissions from '../../hooks/usePermissions';

const EmployeePositions: FC = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [positions, setPositions] = useState<IEmployeePosition[]>();
    const [showForm, setShowForm] = useState(false);

    const hasPermission = usePermissions();
    if(
        !hasPermission(GroupPermissionEnum.EmployeeRead)
        ||
        !hasPermission(GroupPermissionEnum.PositionRead)
    ){
        return <Navigate to="/" replace />
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const searchPositions = () => {
        positionsService.search({ limit: rowsPerPage, offset: page * rowsPerPage })
            .then(res => {
                setPositions(res.items); 
                setCount(res.count);
            })
            .catch(console.log);
    }

    const createPosition = (payload: IEmployeePositionCreate) => {
        positionsService.create(payload)
            .then(searchPositions)
            .catch(console.log);
    }

    const updatePosition = (id: string, payload: IEmployeePositionUpdate) => {
        positionsService.update(id, payload)
            .then(searchPositions)
            .catch(console.log);
    }

    const deletePosition = (id: string) => {
        positionsService.delete(id)
            .then(searchPositions)
            .catch(console.log);
    }

    useEffect(searchPositions, [page, rowsPerPage]);

    if(!positions) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500  }}/>
        </Container>
    )

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Должности</Typography>
            </Breadcrumbs>

            {
                hasPermission(GroupPermissionEnum.EmployeeEdit) && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v=>!v)}>
                            { showForm ? 'Закрыть' : 'Добавить должность' }
                        </Button>
                        { showForm && <EmployeePositionsNew onCreate={createPosition} /> }
                    </Box>
                )
            }

            {
                positions.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 500 , padding: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Должность</TableCell>
                                    {
                                        hasPermission(GroupPermissionEnum.EmployeeEdit) && <TableCell width="120px"></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    positions.map(position => (
                                        <EmployeePositionsItem
                                            position={position}
                                            canEdit={hasPermission(GroupPermissionEnum.EmployeeEdit)}
                                            onUpdate={payload => updatePosition(position.id, payload)}
                                            onDelete={() => deletePosition(position.id)}
                                            key={position.id}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                        <TablePagination
                            count={count}
                            page={page}
                            onPageChange={(_, value) => setPage(value)}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            component="div"
                        />
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default EmployeePositions;