import { FormControl, IconButton, MenuItem, Select, TableCell, TableRow, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { IService, ServiceMeasureEnum, ServiceMeasureEnumLabels, IServiceUpdate } from '../../API/dto/services.dto';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IServiceType } from '../../API/dto/serviceTypes.dto';


interface IProps {
    service: IService,
    serviceTypes: IServiceType[],
    canEdit: boolean,
    onUpdate: (payload: IServiceUpdate) => void,
    onDelete: () => void,
}

const ServicesItem: FC<IProps> = ({ service, serviceTypes, canEdit, onUpdate, onDelete }) => {
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [name, setName] = useState<string>(service.name);
    const [serviceTypeId, setServiceTypeId] = useState<string | undefined>(service.serviceTypeId);
    const [measure, setMeasure] = useState<ServiceMeasureEnum>(service.measure);

    const handleUpdate = () => {
        onUpdate({ name, serviceTypeId, measure });
        setEditMode(false);
    }

    if(isEditMode) return (
        <TableRow>
            <TableCell align="center">
                <TextField
                    type="text"
                    variant="outlined"
                    size='small'
                    fullWidth
                    required={true}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </TableCell>
            <TableCell align="center">
                <FormControl variant='outlined' size='small' fullWidth>
                    <Select value={serviceTypeId} onChange={e => setServiceTypeId(e.target.value)}>
                        {
                            serviceTypes.map(type => (
                                <MenuItem value={type.id} key={type.id}>
                                    {type.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell align="center">
                <FormControl variant='outlined' size="small" fullWidth>
                    <Select value={measure} onChange={e => setMeasure(e.target.value as ServiceMeasureEnum)}>
                        {
                            Object.values(ServiceMeasureEnum).map(measure => <MenuItem value={measure} key={measure}>{ServiceMeasureEnumLabels[measure]}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={() => setEditMode(false)}>
                    <CancelIcon/>
                </IconButton>
                <IconButton onClick={handleUpdate}>
                    <CheckCircleIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    )
    
    return (
        <TableRow>
            <TableCell align="center">{service.name}</TableCell>
            <TableCell align="center">{service.serviceType?.name}</TableCell>
            <TableCell align="center">{ServiceMeasureEnumLabels[service.measure]}</TableCell>
            {
                canEdit && (
                    <TableCell align="center">
                        <IconButton onClick={() => setEditMode(true)}>
                            <EditIcon/>
                        </IconButton>
                        <IconButton onClick={onDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default ServicesItem;