import { Box, Breadcrumbs, Button, Container, FormControl, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { IContract } from '../../API/dto/contracts.dto';
import { ServiceMeasureEnumAbbr } from '../../API/dto/services.dto';
import { ITracker } from '../../API/dto/tracker.dto';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import contractsService from '../../API/services/contracts.service';
import trackerService from '../../API/services/tracker.service';
import usePermissions from '../../hooks/usePermissions';
import useSearchState from '../../hooks/useSearchState';
import CounterpartyPaymentsSummTable from '../../tables/CounterpartyPaymentsSummTable';
import { dateFormat } from '../../utils/dateFormat';
import generateCSVLink from '../../utils/generateCSVLink';
import SearchDate from '../SearchDate';
import SearchObject from '../SearchObject';

const border = '1px solid rgba(224, 224, 224, 1)';

const CounterpartyPaymentsSumm: React.FC = () => {
    const [objectId, setObjectId] = useSearchState('objectId');
    const [contractId, setContractId] = useState('');
    const [dateFrom, setDateFrom] = useSearchState('dateFrom', dayjs().subtract(1, 'week').format(dateFormat));
    const [dateTo, setDateTo] = useSearchState('dateTo', dayjs().format(dateFormat));
    const [contracts, setContracts] = useState<IContract[]>([]);
    const [trackers, setTrackers] = useState<ITracker[]>([]);
    
    const hasPermission = usePermissions();
    if(!hasPermission(GroupPermissionEnum.AnalyticsCounterpartyCost)){
        return <Navigate to="/" replace />
    }

    useEffect(() => {
        contractsService.searchContracts({ objectId })
            .then(res => res.items)
            .then(setContracts)
            .catch(console.log);
    }, [objectId])

    useEffect(() => {
        trackerService.searchTrackers({
            objectId,
            dateFrom: dayjs(dateFrom).format(dateFormat),
            dateTo: dayjs(dateTo).format(dateFormat),
          })
              .then(res => res.items)
              .then(setTrackers)
              .catch(console.log);
    }, [objectId, dateFrom, dateTo])

    const filteredContracts = useMemo(() => contracts.filter(contract => !contractId || contractId === contract.id), [contracts, contractId]);
    const table = useMemo(() => new CounterpartyPaymentsSummTable(filteredContracts, trackers), [filteredContracts, trackers]);

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Суммарный расчет для заказчика</Typography>
            </Breadcrumbs>

            <Stack direction='row' gap='10px' flexWrap='wrap' marginTop='40px'>
                <SearchObject objectId={objectId} setObjectId={setObjectId} />
                <FormControl variant='filled' style={{ width: 270 }} disabled={!objectId}>
                    <InputLabel id="demo-simple-select-label">Подразделение</InputLabel>
                    <Select
                        sx={{ height: '56px' }}
                        size='small'
                        value={contractId}
                        onChange={(e) => setContractId(e.target.value)}
                    >
                        <MenuItem>Не выбрано</MenuItem>
                        {
                            contracts?.map((contract) => (
                                <MenuItem
                                    value={contract.id}
                                    key={contract.id}>
                                    {contract.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <SearchDate date={dateFrom} setDate={setDateFrom}/>
                <SearchDate date={dateTo} setDate={setDateTo}/>
            </Stack>
            
            {
                Boolean(contracts.length) && (
                    <Fragment>
                        <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ border, width: '40%' }}>
                                            <Typography fontSize={18}>Услуги</Typography>
                                        </TableCell>
                                        <TableCell sx={{ border, width: '20%' }}>
                                            <Typography fontSize={18}>Цена за ед. измерения</Typography>
                                        </TableCell>
                                        <TableCell sx={{ border, width: '20%' }}>
                                            <Typography fontSize={18}>Количество</Typography>
                                        </TableCell>
                                        <TableCell sx={{ border, width: '20%' }}>
                                            <Typography fontSize={18}>Цена</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        filteredContracts.map((contract => (
                                            <React.Fragment key={contract.id}>
                                                {
                                                    !contractId && (
                                                        <TableRow>
                                                            <TableCell colSpan={4} align="center">
                                                                <Typography fontSize={16}>{contract.name}  {Boolean(contract.costForCounterpartyFixed) && `(Фиксированная цена по договору ${contract.costForCounterpartyFixed} ₽)`}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                                {
                                                    contract.services.map(({ service }) => {
                                                        const tableData = table.getContractServiceData(contract.id, service.id);
                                                        return (
                                                            <TableRow key={service.id}>
                                                                <TableCell sx={{ border }}>
                                                                    <Typography fontSize={16}>{service.name}, {ServiceMeasureEnumAbbr[service.measure]}</Typography>
                                                                </TableCell>
                                                                <TableCell sx={{ border }} align="center">
                                                                    <Typography fontSize={16}>{ contract.costForCounterpartyFixed ? '—' : tableData.cost }</Typography>
                                                                </TableCell>
                                                                <TableCell sx={{ border }} align="center">
                                                                    <Typography fontSize={16}>{tableData.amount}</Typography>
                                                                </TableCell>
                                                                <TableCell sx={{ border }} align="center">
                                                                    <Typography fontSize={16}>{ contract.costForCounterpartyFixed ? '—' : tableData.price }</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </React.Fragment>
                                        )))
                                    }
                                    <TableRow>
                                        <TableCell colSpan={3} sx={{ border }}><Typography fontSize={18}>Итого</Typography></TableCell>
                                        <TableCell sx={{ border }} align="center">
                                            <Typography fontSize={18}>{table.getTotalPrice()}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box sx={{marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <a href={generateCSVLink(table.getCSVTable())} download="CounterpartyPaymentsSumm.csv" >
                                <Button variant="contained">Скачать</Button>
                            </a>
                        </Box>
                    </Fragment>
                )
            }
        </Container>
    )
}

export default CounterpartyPaymentsSumm;