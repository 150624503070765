import {  useMemo, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { IGroup, } from '../../API/dto/groups.dto';
import groupsService from '../../API/services/groups.service';
import GroupPermissionsItem from './groupPermissionsItem';
import GroupPermissionsNew from './GroupPermissionsNew';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import usePermissions from '../../hooks/usePermissions';

const GroupPermissions = () => {
    const { groupId } = useParams()
    const [group, setGroup] = useState<IGroup>();
    const [showForm, setShowForm] = useState(false);

    const enumArray = Object.values(GroupPermissionEnum);

    const hasPermission = usePermissions();
    if(
        !hasPermission(GroupPermissionEnum.GroupRead)
    ){
        return <Navigate to="/" replace />
    }

    const searchGroup = () => {
        groupsService.searchGroups({id: groupId})
        .then(r => setGroup(r.items[0]))
        .catch(er => console.log(er))
    }

    useMemo(searchGroup, [groupId])
    
    if(!group) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500  }}/>
        </Container>
    )

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Link to="/groups"><Typography fontSize={18}>Группы</Typography></Link>
                <Typography color="black" fontSize={18}>Разрешения группы &quot;{group ? group.name : ''}&quot;</Typography>
            </Breadcrumbs>

            {
                hasPermission(GroupPermissionEnum.GroupEdit) && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v=>!v)}>
                            { showForm ? 'Закрыть' : 'Добавить разрешение' }
                        </Button>
                        { 
                            showForm && <GroupPermissionsNew permissions={group.permissions} searchGroup={searchGroup} groupId={group.id} enumArray={enumArray} /> 
                        }
                    </Box>
                )
            }

            {
                group.permissions.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 500, padding: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" >разрешение</TableCell>
                                    {
                                        hasPermission(GroupPermissionEnum.GroupEdit) && <TableCell width="120px"></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    group.permissions.map((permission, i) => (
                                        <GroupPermissionsItem permissions={group.permissions} permission={permission} groupId={group.id} key={i} searchGroup={searchGroup} />
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }
        </Container>
    )
        
}

export default GroupPermissions;