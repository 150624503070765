import { FC, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { IObject } from '../API/dto/objects.dto';
import objectsService from '../API/services/objects.service';

interface IProps {
    objectId: string,
    setObjectId: (value: string) => void
}

const SearchObject: FC<IProps> = ({ objectId, setObjectId }) => {
    const [objects, setObjects] = useState<IObject[]>();

    useEffect(() => {
            objectsService.searchObjects()
                .then(res => res.items)
                .then(setObjects)
                .catch(console.log)
    }, [])

    return (
        <FormControl variant='filled' style={{ width: 270 }}>
            <InputLabel id="demo-simple-select-label">Объект</InputLabel>
            <Select
                sx={{ height: '56px' }}
                size='small'
                value={objectId}
                onChange={(e) => setObjectId(e.target.value)}
            >
                <MenuItem value="">Не выбрано</MenuItem>
                {
                    objects?.map((object) => (
                        <MenuItem
                            value={object.id}
                            key={object.id}>
                            {object.name}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default SearchObject;