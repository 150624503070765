import { IconButton, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import groupsService from '../../API/services/groups.service';
import usePermissions from '../../hooks/usePermissions';


interface IProps {
    groupId: string,
    permission: GroupPermissionEnum, 
    permissions: GroupPermissionEnum[],
    searchGroup: () => void
}

const GroupsPermissonsItem: FC<IProps> = ({ permissions, permission, groupId, searchGroup}) => {
    const hasPermission= usePermissions();
    const onDelete = () => {
        groupsService.updateGroup(groupId, {
            permissions: permissions.filter(perm => perm !== permission)
        })
        .then(searchGroup)
        .catch(er => console.log(er))
    }

    return (
        <TableRow>
            <TableCell align="center">{permission}</TableCell>
            {
                hasPermission(GroupPermissionEnum.GroupEdit) && (
                    <TableCell align="center">
                        <IconButton onClick={onDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default GroupsPermissonsItem;