import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../dto/common.dto';
import { IEmployeeGroup, IEmployeeGroupCreate, IEmployeeGroupSearch, IEmployeeGroupUpdate } from '../dto/employeeGroups.dto';

export class EmployeeGroupsService {
    constructor(private api: ApiClient){}

    public async search(search?: IEmployeeGroupSearch){
        return this.api.get<SearchResult<IEmployeeGroup>>('employees-on-groups', search);
    }

    public async create(payload: IEmployeeGroupCreate){
        return this.api.post<IEmployeeGroup>('employees-on-groups', payload);
    }

    public async update(id: string, payload: IEmployeeGroupUpdate){
        return this.api.patch<IEmployeeGroup>('employees-on-groups/' + id, payload);
    }

    public async delete(id: string){
        return this.api.delete<void>('employees-on-groups/' + id);
    }
}

export default new EmployeeGroupsService(apiClient);