import {  IconButton, MenuItem, Select, TableCell, TableRow } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IEmployee } from '../../API/dto/employees.dto';
import { IContractEmployee } from '../../API/interfaces/contractEmployees';
import employeesService from '../../API/services/employees.service';

interface IProps {
    contractEmployee: IContractEmployee,
    employees: IEmployee[],
    canEdit: boolean,
    onUpdate: (payload: string) => void
    onDelete: () => void
}

const ContractEmployeesItem: FC<IProps> = ({ contractEmployee, employees, canEdit, onUpdate, onDelete }) => {
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [employeeId, setEmployeeId] = useState<string>(contractEmployee.employeeId);
    const [employeeName, setEmployeeName] = useState<string>('');

    useEffect(() => {
        employeesService.searchEmployees({id: contractEmployee.employeeId})
        .then(r => setEmployeeName(r.items[0].firstName +' ' + r.items[0].lastName));
    }, [employeeId])
    
    const handleUpdate = () => { 
        onUpdate(employeeId)
        setEditMode(false)
    }

    if(canEdit && isEditMode) return (
        <TableRow>
            <TableCell align="center">
                <Select size="small" fullWidth value={employeeId} onChange={e => setEmployeeId(e.target.value)} >
                    <MenuItem value={contractEmployee.employeeId} key={contractEmployee.employeeId}>
                        {employeeName}
                    </MenuItem>
                    {
                        employees.map((employee) => (
                            <MenuItem value={employee.id} key={employee.id}>
                                {employee.firstName + ' ' + employee.lastName}
                            </MenuItem>
                        ))
                    }
                </Select>
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={() => setEditMode(false)}>
                    <CancelIcon/>
                </IconButton>
                <IconButton onClick={handleUpdate} disabled={!employeeId}>
                    <CheckCircleIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    )
    
    return (
        <TableRow>
            <TableCell align="center">{employeeName}</TableCell>
            {
                canEdit && (
                    <TableCell align="center">
                        <IconButton onClick={onDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default ContractEmployeesItem;