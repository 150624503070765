import { IEntity, IEntitySearch } from './common.dto';
import { IEmployeePosition } from './employeePositions.dto';
import { IEmployeeService } from './employeeServices.dto';
import { IContractEmployee } from '../interfaces/contractEmployees';
import { IEmployeeGroup } from './employeeGroups.dto';

export interface IEmployee extends IEntity {
  firstName: string;
  middleName?: string;
  lastName: string;
  phoneNumber: string;
  email?: string;
  citizenship?: string;
  
  status: EmployeeStatusEnum;
  employmentType?: EmployeeEmploymentTypeEnum;
  paymentType?: EmployeePaymentTypeEnum;

  positionId?: string;
  position?: IEmployeePosition;
  groups: IEmployeeGroup[]
  services: IEmployeeService[];
  contracts: IContractEmployee[];

  canAuth: boolean;
  isSuperUser: boolean;
}

export interface IEmployeeSearch extends IEntitySearch {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  citizenship?: string;

  status?: EmployeeStatusEnum;
  employmentType?: EmployeeEmploymentTypeEnum;
  paymentType?: EmployeePaymentTypeEnum;

  positionId?: string;

  canAuth?: boolean;
  isSuperUser?: boolean;
}

export interface IEmployeeCreate {
  firstName: string;
  middleName?: string;
  lastName: string;
  phoneNumber: string;
  email?: string;
  citizenship?: string;
  
  status: EmployeeStatusEnum;
  employmentType: EmployeeEmploymentTypeEnum;
  paymentType: EmployeePaymentTypeEnum;
  
  position: { id?: string, name?: string };
  services?: string[];
  
  canAuth?: boolean;
}

export interface IEmployeeUpdate {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phoneNumber?: string;
  citizenship?: string;
  email?: string;

  status?: EmployeeStatusEnum;
  employmentType?: EmployeeEmploymentTypeEnum;
  paymentType?: EmployeePaymentTypeEnum;

  position?: { id?: string, name?: string };
  services?: string[];

  canAuth?: boolean;
}

export enum EmployeeStatusEnum {
  Employee = 'Работник',
  Intern = 'Стажер',
  Fired = 'Уволен',
  Unofficial = 'Не официально',
  Reserve = 'Резерв',
  OutSource = 'Сторонняя компания',
}

export enum EmployeeEmploymentTypeEnum {
  someType = 'someType',
  someOtherType = 'someOtherType',
}

export enum EmployeePaymentTypeEnum {
  cash = 'cash',
  card = 'card',
}