import { FC, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import EmployeesNew from './EmployeesNew';
import EmployeesItem from './EmployeesItem';
import { IEmployeeCreate, IEmployee, IEmployeeUpdate } from '../../API/dto/employees.dto';
import employeesService from '../../API/services/employees.service';
import { IEmployeePosition } from '../../API/dto/employeePositions.dto';
import positionsService from '../../API/services/positions.service';
import SearchObject from '../SearchObject';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';

const Employees: FC = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [employees, setEmployees] = useState<IEmployee[]>();
    const [positions, setPositions] = useState<IEmployeePosition[]>([]);
    const [showForm, setShowForm] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [objectId, setObjectId] = useState<string>('');

    const hasPermission = usePermissions();
    if(!hasPermission(GroupPermissionEnum.EmployeeRead)){
        return <Navigate to="/" replace />
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const searchEmployees = () => {
        employeesService.searchEmployees({ limit: rowsPerPage, offset: page * rowsPerPage })
            .then(res => {
                setEmployees(res.items);
                setCount(res.count);
                setShowForm(false);
            })
            .catch(console.log);
    }

    const createEmployee = (payload: IEmployeeCreate) => {
        employeesService.createEmployee(payload)
            .then(searchEmployees)
            .catch(console.log);
    }

    const updateEmployee = (id: string, payload: IEmployeeUpdate) => {
        employeesService.updateEmployee(id, payload)
            .then(searchEmployees)
            .catch(console.log);
    }

    const deleteEmployee = (id: string) => {
        employeesService.deleteEmployee(id)
            .then(searchEmployees)
            .catch(console.log);
    }

    useEffect(searchEmployees, [page, rowsPerPage]);

    useEffect(() => {
        positionsService.search()
            .then(res => res.items)
            .then(setPositions)
            .catch(console.log)
    }, [])

    if (!employees) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500 }} />
        </Container>
    )

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Сотрудники</Typography>
            </Breadcrumbs>

            {
                hasPermission(GroupPermissionEnum.EmployeeEdit) && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v => !v)}>
                            {showForm ? 'Закрыть' : 'Добавить сотрудника'}
                        </Button> <br />
                        {showForm && <EmployeesNew positions={positions} onCreate={createEmployee} />}
                    </Box>
                )
            }

            <Box sx={{ marginTop: '40px' }}>
                <Button variant="outlined" size="large" sx={{ marginTop: '10px' }} onClick={() => setShowFilter(v => !v)}>
                    {showFilter ? 'Закрыть' : 'Добавить фильтр'}
                </Button>
                <Box sx={{ marginTop: '20px' }}>{showFilter && <SearchObject objectId={objectId} setObjectId={setObjectId} />}</Box>
            </Box>

            {
                employees.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 500, padding: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Фамилия</TableCell>
                                    <TableCell align="center">Имя</TableCell>
                                    <TableCell align="center">Отчество</TableCell>
                                    <TableCell align="center">Телефон</TableCell>
                                    <TableCell align="center">Email</TableCell>
                                    <TableCell align="center">Гражданство</TableCell>
                                    <TableCell align="center">Должность</TableCell>
                                    <TableCell align="center">Статус</TableCell>
                                    <TableCell align="center">Может<br/>авторизироваться</TableCell>
                                    {
                                        hasPermission(GroupPermissionEnum.ContractsOnEmployeesRead) && <TableCell align="center">Объект</TableCell>
                                    }
                                    {
                                        hasPermission(GroupPermissionEnum.EmployeesOnGroupsRead) && <TableCell align="center">Группы</TableCell>
                                    }
                                    {
                                        hasPermission(GroupPermissionEnum.EmployeesOnServicesRead) && <TableCell align="center">Услуги</TableCell>
                                    }
                                    {
                                        hasPermission(GroupPermissionEnum.DocumentRead) && <TableCell align="center">Документы</TableCell>
                                    }
                                    {
                                        hasPermission(GroupPermissionEnum.EmployeeEdit) && <TableCell width="120px"></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    employees.filter(employee => !objectId || employee.contracts.find(({ contract }) => contract?.objectId == objectId))
                                        .map(employee => (
                                            <EmployeesItem
                                                employee={employee}
                                                positions={positions}
                                                onUpdate={payload => updateEmployee(employee.id, payload)}
                                                onDelete={() => deleteEmployee(employee.id)}
                                                key={employee.id}
                                            />
                                        ))
                                }
                            </TableBody>
                        </Table>
                        <TablePagination
                            count={count}
                            page={page}
                            onPageChange={(_, value) => setPage(value)}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            component="div"
                        />
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default Employees;