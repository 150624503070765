
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { IObject } from '../../API/dto/objects.dto';

interface IProps {
    onCreate: (payload: string) => void,
    objects: IObject[]
}

const EmployeeDepartmentsNew: FC<IProps> = ({ onCreate, objects }) => {
    const [objectId, setObjectId] = useState<string>('');
    const departments = objects.find(object => object.id === objectId)?.objectDepartments || [];
    const [departmentId, setDepartmentId] = useState<string>('');

    const handleCreate = () => onCreate(departmentId);

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Stack direction="row" flexWrap="wrap" gap="10px">
                <FormControl variant='filled' required sx={{ width: '300px' }}>
                    <InputLabel>Объект</InputLabel>
                    <Select value={objectId} onChange={e => { setObjectId(e.target.value); setDepartmentId('') }} >
                        {
                            objects.map((object) => (
                                <MenuItem value={object.id} key={object.id}>
                                    {object.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl variant='filled' required sx={{ width: '300px' }}>
                    <InputLabel>Подразделение</InputLabel>
                    <Select value={departmentId} onChange={e => setDepartmentId(e.target.value)} >
                        {
                            departments.map((department) => (
                                <MenuItem value={department.id} key={department.id}>
                                    {department.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Stack>
            <Button sx={{ marginTop: '20px' }}
                variant="outlined"
                size="large"
                onClick={handleCreate}
                disabled={!departmentId}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default EmployeeDepartmentsNew;