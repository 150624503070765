import apiClient, { ApiClient } from '../apiClient';
import { ChangePasswordDto, IAuth, RegisterDto, SignupDto } from '../dto/auth.dto';

export class AuthService {
    constructor(private api: ApiClient){}

    public async login(credentials: SignupDto){
        return this.api.post<IAuth>('auth/signup', credentials)
            .then(auth => this.api.setToken(auth.accessToken));
    }

    public async refresh(){
        return this.api.post<IAuth>('auth/refresh-token')
            .then(auth => this.api.setToken(auth.accessToken));
    }

    public async register(credentials: RegisterDto){
        return this.api.post<IAuth>('auth/register', credentials)
            .then(auth => this.api.setToken(auth.accessToken));
    }

    public async changePassword(credentials: ChangePasswordDto){
        return this.api.post<void>('auth/change-password', credentials);
    }
}


export default new AuthService(apiClient);