import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import ServiceTrackers from './components/ServiceTrackers/ServiceTrackers';
import AuthPage from './components/AuthPage';
import Page404 from './components/Page404';
import AnalyticsPage from './components/AnalyticsPage';
import ContractServices from './components/ContractServices/ContractServices';
import EmployeeServices from './components/EmployeeServices/EmployeeServices';
import ObjectContracts from './components/ObjectContracts/ObjectContracts';
import Services from './components/Services/Services';
import ServiceTypes from './components/ServiceTypes/ServiceTypes';
import Objects from './components/Objects/Objects';
import Employees from './components/Employees/Employees';
import EmployeeDepartments from './components/EmployeeDepartments/EmployeeDepartments';
import PrivateRoutes from './components/PrivateRoutes';
import PositionPayments from './components/Analytics/PositionPayments';
import EmployeePositions from './components/EmployeePositions/EmployeePositions';
import PositionAmountsPerDay from './components/Analytics/PositionAmountsPerDay';
import EmployeeDocuments from './components/EmployeeDocuments/EmployeeDocuments';
import ContractEmployees from './components/ContractEmployees/ContractEmployees';
import EmployeePayments from './components/Analytics/EmployeePayments';
import CounterpartyPayments from './components/Analytics/CounterpartyPayments';
import CounterpartyPaymentsSumm from './components/Analytics/CounterpartyPaymentsSumm';
import GroupPermissions from './components/GroupPermissions/GroupPermissions';
import GroupEmployees from './components/GroupEmployees/GroupEmployees';
import Groups from './components/Groups/Groups';
import Loading from './components/Loading';
import Context from './context';
import EmployeeGroups from './components/EmployeesGroup/EmployeeGroups';
import Settings from './components/Settings/Settings';

export default function App() {
    return (
        <Context>
            <BrowserRouter>
                <Routes>
                    <Route element={<Loading />}>
                        <Route path='/auth' element={<AuthPage />} />
                        <Route element={<PrivateRoutes />}>
                            <Route element={<Layout />}>
                                <Route path='/' />
                                <Route path='/objects' element={<Objects />} />
                                <Route path='/objects/:objectId/contracts' element={<ObjectContracts />} />
                                <Route path='/contracts/:contractId/services' element={<ContractServices />} />
                                <Route path='/contracts/:contractId/employees' element={<ContractEmployees />} />

                                <Route path='/service-types' element={<ServiceTypes />} />
                                <Route path='/services' element={<Services />} />

                                <Route path='/employee-positions' element={<EmployeePositions />} />
                                <Route path='/employees' element={<Employees />} />
                                <Route path='/employees/:employeeId/services' element={<EmployeeServices />} />
                                <Route path='/employees/:employeeId/departments' element={<EmployeeDepartments />} />
                                <Route path='/employees/:employeeId/documents' element={<EmployeeDocuments />} />
                                <Route path='/employees/:employeeId/groups' element={<EmployeeGroups />} />

                                <Route path='/timesheet' element={<ServiceTrackers />} />

                                <Route path='/analytics' element={<AnalyticsPage />} />
                                <Route path='/analytics/position-payments' element={<PositionPayments />} />
                                <Route path='/analytics/position-amount' element={<PositionAmountsPerDay />} />
                                <Route path='/analytics/employee-payments' element={<EmployeePayments />} />
                                <Route path='/analytics/counterparty-payments' element={<CounterpartyPayments />} />
                                <Route path='/analytics/counterparty-payments-summ' element={<CounterpartyPaymentsSumm />} />

                                <Route path='/groups' element={<Groups />} />
                                <Route path='/groups/:groupId/permissions' element={<GroupPermissions />} />
                                <Route path='/groups/:groupId/employees' element={<GroupEmployees />} />

                                <Route path='/settings' element={<Settings />} />
                                <Route path='*' element={<Page404 />} />
                            </Route>
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </Context>
    )
};

