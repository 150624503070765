import React, { useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
    TableContainer, Paper, Table, TableCell, TableRow, TableHead, TableBody, Typography
} from '@mui/material';
import ServiceTrackersItem from './ServiceTrackersItem';
import trackerService from '../../API/services/tracker.service';
import { ITracker } from '../../API/dto/tracker.dto';
import contractsService from '../../API/services/contracts.service';
import { IContract } from '../../API/dto/contracts.dto';
import { dateFormat } from '../../utils/dateFormat';
import { ServiceMeasureEnumAbbr } from '../../API/dto/services.dto'

function trackersMapReducer(map: Map<string, ITracker>, tracker: ITracker): Map<string, ITracker> {
    map.set(tracker.contractId+'-'+tracker.serviceId+'-'+dayjs(tracker.dateAt).format(dateFormat), tracker);
    return map;
}

interface IProps {
    employeeId: string,
    objectId: string,
    contractId: string,
    dateFrom: string,
    dateTo: string
}

const ServiceTrackersTable: React.FC<IProps> = ({ employeeId, objectId, contractId, dateFrom, dateTo }) => {
    const dates = useMemo(() => {
        const dates = [];
        let date = dayjs(dateFrom);
        while (date.isBefore(dayjs(dateTo)) || date.isSame(dayjs(dateTo))) {
            dates.push(date);
            date = date.add(1, 'day');
        }
        return dates;
    }, [dateFrom, dateTo])

    const [contracts, setContracts] = useState<IContract[]>([]);
    const [trackersMap, setTrackersMap] = useState<Map<string, ITracker>>();

    useEffect(() => {
        contractsService.searchContracts({ objectId })
            .then(res => res.items)
            .then(setContracts)
            .catch(console.log);
    }, [objectId])

    useEffect(() => {
        trackerService.searchTrackers({
            employeeId: employeeId,
            objectId: objectId,
            dateFrom: dayjs(dateFrom).format(dateFormat),
            dateTo: dayjs(dateTo).format(dateFormat),
        })
            .then((res) => setTrackersMap(res.items.reduce(trackersMapReducer, new Map())))
            .catch(console.log);
    }, [employeeId, objectId, dateFrom, dateTo])

    if (!dates || !contracts || !trackersMap) {
        return null;
    }

    const createTracker = (contractId: string, serviceId: string, dateAt: Dayjs, amount: number) => {
        trackerService.createTracker({
            employeeId,
            objectId,
            serviceId,
            contractId,
            amount,
            dateAt: dateAt.format(dateFormat)
        })
            .then(tracker => setTrackersMap((map) => trackersMapReducer(new Map(map), tracker)))
            .catch(console.log);
    }

    const updateTracker = (trackerId: string, amount: number) => {
        trackerService.updateTracker(trackerId, { amount })
            .then(tracker => setTrackersMap((map) => trackersMapReducer(new Map(map), tracker)))
            .catch(console.log);
    }

    const dateAmount = new Map<string, number>();

    return (
        <TableContainer component={Paper}>
            <Table >
                <TableHead>
                    <TableRow>
                        <TableCell rowSpan={2} sx={{ minWidth: '160px', border: '1px solid rgba(0,0,0, .2)' }}>
                            <Typography fontSize={18}>Название услуги</Typography>
                        </TableCell>
                        {
                            Object.entries(dates.reduce((months, date) => {
                                const monthString = date.format('MMMM YYYY')
                                months[monthString] = months[monthString] + 1 || 1;
                                return months;
                            }, {} as Record<string, number>)).map(([monthString, count]) => (
                                <TableCell
                                    colSpan={count}
                                    align='center'
                                    sx={{ border: '1px solid rgba(0,0,0, .2)' }}
                                    key={monthString}
                                >
                                    <Typography fontSize={16}>{monthString}</Typography>
                                </TableCell>
                            ))
                        }
                    </TableRow>
                    <TableRow>
                        {
                            dates.map((date) => (
                                <TableCell
                                    align='center'
                                    sx={{ minWidth: '60px', border: '1px solid rgba(0,0,0, .2)', padding: '10px 5px' }}
                                    key={date.format(dateFormat)}
                                >
                                    <Typography fontSize={14}>{date.format('DD dd')}</Typography>
                                </TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                       contracts.filter(contract => !contractId || contractId == contract.id).map(contract => (
                            <React.Fragment key={contract.id}>
                                <TableRow>
                                    <TableCell colSpan={dates.length + 1} align="center" sx={{ border: '1px solid rgba(0,0,0, .2)' }}>
                                        <Typography fontSize={16}>{contract.name}</Typography>
                                    </TableCell>
                                </TableRow>
                                {
                                    contract.services.map(({ contractId, serviceId, service }) => {
                                        let serviceAmount = 0;
                                        dates.forEach(date => {
                                            const dateString = date.format(dateFormat);
                                            const tracker = trackersMap.get(contractId + '-' + serviceId + '-' + dateString);
                                            if (tracker) {
                                                const currentdateAmount = dateAmount.get(dateString) || 0;
                                                dateAmount.set(dateString, currentdateAmount + tracker.amount);
                                                serviceAmount += tracker.amount;
                                            }
                                        })
                                        return (
                                            <TableRow key={service.id} hover={true}>
                                                <TableCell sx={{ border: '1px solid rgba(0,0,0, .2)' }} key={service.id}>
                                                    <Typography fontSize={16}>{service.name}, {serviceAmount || ''}&nbsp;{ServiceMeasureEnumAbbr[service.measure]}</Typography>
                                                </TableCell>
                                                {
                                                    dates.map((date) => {
                                                        const dateString = date.format(dateFormat);
                                                        const tracker = trackersMap.get(contractId + '-' + serviceId + '-' + dateString);
                                                        return  <ServiceTrackersItem
                                                                    tracker={tracker}
                                                                    onCreate={(amount) => createTracker(contractId, serviceId, date, amount)}
                                                                    onUpdate={updateTracker} 
                                                                    key={dateString}
                                                                />
                                                    })
                                                }
                                            </TableRow>
                                        )
                                    })
                                }
                            </React.Fragment>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>  
    )
}

export default ServiceTrackersTable;