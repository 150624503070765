import {  IconButton, MenuItem, Select, TableCell, TableRow } from '@mui/material';
import { FC, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IService } from '../../API/dto/services.dto';
import { IEmployeeService } from '../../API/dto/employeeServices.dto';
import { IServiceType } from '../../API/dto/serviceTypes.dto';


interface IProps {
    types: IServiceType[],
    services: IService[],
    employeeService: IEmployeeService,
    canEdit: boolean,
    onUpdate: (payload: string) => void
    onDelete: () => void
}

const EmployeeServicesItem: FC<IProps> = ({ types, services, employeeService, canEdit, onUpdate, onDelete }) => {
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [typeId, setTypeId] = useState<string>(employeeService.service?.serviceTypeId || '');
    const typeServices =  services.filter(service => !typeId || !service.serviceTypeId ||service.serviceTypeId === typeId);
    const [serviceId, setServiceId] = useState<string>(employeeService.serviceId);
    
    const handleUpdate = () => onUpdate(serviceId)

    if(canEdit && isEditMode) return (
        <TableRow>
            <TableCell align="center">
                <Select size="small" fullWidth value={typeId} onChange={e => setTypeId(e.target.value)}>
                    <MenuItem value="" key="">Не выбрано</MenuItem>
                    <MenuItem value={employeeService.service?.serviceType?.id} key={employeeService.service?.serviceType?.id}>
                        {employeeService.service?.serviceType?.name || 'Не выбрано'}
                    </MenuItem>
                    {
                        types.map(type => (
                            <MenuItem value={type.id} key={type.id}>
                                {type.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </TableCell>
            <TableCell align="center">
                <Select size="small" fullWidth value={serviceId} onChange={e => setServiceId(e.target.value)}>
                    <MenuItem value={employeeService.serviceId} key={employeeService.serviceId}>
                        {employeeService.service?.name}
                    </MenuItem>
                    {
                        typeServices.map(service => (
                            <MenuItem value={service.id} key={service.id}>
                                {service.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={() => setEditMode(false)}>
                    <CancelIcon/>
                </IconButton>
                <IconButton onClick={handleUpdate} disabled={!serviceId}>
                    <CheckCircleIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    )
    
    return (
        <TableRow>
            <TableCell align="center">{employeeService.service?.serviceType?.name}</TableCell>
            <TableCell align="center">{employeeService.service?.name}</TableCell>
            {
                canEdit && (
                    <TableCell align="center">
                        <IconButton onClick={() => setEditMode(true)}>
                            <EditIcon/>
                        </IconButton>
                        <IconButton onClick={onDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default EmployeeServicesItem;