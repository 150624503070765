import {  IconButton, MenuItem, Select, TableCell, TableRow } from '@mui/material';
import { FC, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IObject } from '../../API/dto/objects.dto';
import { IDepartmentEmployee } from '../../API/dto/departmentEmployees.dto';

interface IProps {
    objects: IObject[],
    employeeDepartment: IDepartmentEmployee,
    onUpdate: (payload: string) => void
    onDelete: () => void
}

const EmployeeDepartmentsItem: FC<IProps> = ({ objects, employeeDepartment, onUpdate, onDelete }) => {
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [objectId, setObjectId] = useState<string>(employeeDepartment.objectDepartment?.objectId || '');
    const departments = objects.find(object => object.id === objectId)?.objectDepartments || [];
    const [departmentId, setDepartmentId] = useState<string>(employeeDepartment.objectDepartmentId);
    
    const handleUpdate = () => { 
        onUpdate(departmentId)
        setEditMode(false)
    }

    if(isEditMode) return (
        <TableRow>
            <TableCell align="center">
                <Select size="small" fullWidth value={objectId} onChange={e => { setObjectId(e.target.value); setDepartmentId('') }} >
                    <MenuItem value={employeeDepartment.objectDepartment?.objectId} key={employeeDepartment.objectDepartment?.objectId}>
                        {employeeDepartment.objectDepartment?.object?.name}
                    </MenuItem>
                    {
                        objects.map((object) => (
                            <MenuItem value={object.id} key={object.id}>
                                {object.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </TableCell>
            <TableCell align="center">
                <Select size="small" fullWidth value={departmentId} onChange={e => setDepartmentId(e.target.value)} >
                    <MenuItem value={employeeDepartment.objectDepartmentId} key={employeeDepartment.objectDepartmentId}>
                        {employeeDepartment.objectDepartment?.name}
                    </MenuItem>
                    {
                        departments.map((department) => (
                            <MenuItem value={department.id} key={department.id}>
                                {department.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={() => setEditMode(false)}>
                    <CancelIcon/>
                </IconButton>
                <IconButton onClick={handleUpdate} disabled={!departmentId}>
                    <CheckCircleIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    )
    
    return (
        <TableRow>
            <TableCell align="center">{employeeDepartment.objectDepartment?.object?.name}</TableCell>
            <TableCell align="center">{employeeDepartment.objectDepartment?.name}</TableCell>
            <TableCell align="center">
                <IconButton onClick={() => setEditMode(true)}>
                    <EditIcon/>
                </IconButton>
                <IconButton onClick={onDelete}>
                    <DeleteIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

export default EmployeeDepartmentsItem;