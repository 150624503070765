
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import groupsService from '../../API/services/groups.service';

interface IProps {
    permissions: GroupPermissionEnum[],
    searchGroup: () => void,
    groupId: string,
    enumArray: GroupPermissionEnum[]
}

const GroupsPermissionsNew: FC<IProps> = ({ permissions, searchGroup, groupId, enumArray }) => {
    const [addedPermission, setAddedPermission] = useState<GroupPermissionEnum>();

    const aviablePermissions = enumArray.filter(perm => !permissions.includes(perm))

    const handleCreate = () => {
        groupsService.updateGroup(groupId, {
            permissions: [...permissions, addedPermission as GroupPermissionEnum]
        })
        .then(searchGroup)
        .catch(er => console.log(er))
    }

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Stack direction="row" flexWrap="wrap" gap="10px">
                <FormControl variant='filled' required sx={{ width: '300px' }}>
                    <InputLabel>Разрешение</InputLabel>
                    <Select value={addedPermission} onChange={e => setAddedPermission(e.target.value as GroupPermissionEnum)} >
                        {
                            
                            aviablePermissions.map((permission, i) => (
                                <MenuItem value={permission} key={i}>
                                    {permission}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Stack>
            <Button sx={{ marginTop: '20px' }}
                variant="outlined"
                size="large"
                onClick={handleCreate}
                disabled={!addedPermission}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default GroupsPermissionsNew;