import { Box, Stack, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { FC, useState } from 'react';
import { IGroup } from '../../API/dto/groups.dto';

interface IProps {
    groups: IGroup[];
    handleCreate: (payload: string) => void;
}

const EmployeeGroupsNew: FC<IProps> = ({ groups, handleCreate }) => {
    const [groupId, setGroupId] = useState<string>('');

    const onCreate = () => { handleCreate(groupId) }

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Stack direction="row" flexWrap="wrap" gap="10px">
                <FormControl variant='filled' required sx={{ width: '300px' }}>
                    <InputLabel>Группа</InputLabel>
                    <Select value={groupId} onChange={e => setGroupId(e.target.value)} >
                        {
                            groups.map((group) => (
                                <MenuItem value={group.id} key={group.id}>
                                    {group.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Stack>
            <Button sx={{ marginTop: '20px' }}
                variant="outlined"
                size="large"
                onClick={onCreate}
                disabled={!groupId}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default EmployeeGroupsNew;