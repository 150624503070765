
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { IEmployee } from '../../API/dto/employees.dto';

interface IProps {
    onCreate: (payload: string) => void;
    employees: IEmployee[];
}

const GroupEmployeesNew: FC<IProps> = ({ onCreate, employees }) => {
    const [employeeId, setEmployeeId] = useState<string>('');

    const handleCreate = () => onCreate(employeeId);

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Stack direction="row" flexWrap="wrap" gap="10px">
                <FormControl variant='filled' required sx={{ width: '300px' }}>
                    <InputLabel>Сотрудник</InputLabel>
                    <Select value={employeeId} onChange={e => setEmployeeId(e.target.value)} >
                        {
                            employees.map((employee) => (
                                <MenuItem value={employee.id} key={employee.id}>
                                    {employee.firstName + ' ' + employee.lastName}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Stack>
            <Button sx={{ marginTop: '20px' }}
                variant="outlined"
                size="large"
                onClick={handleCreate}
                disabled={!employeeId}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default GroupEmployeesNew;