import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../dto/common.dto';
import { IDepartmentEmployee, IDepartmentEmployeeCreate, IDepartmentEmployeeSearch, IDepartmentEmployeeUpdate } from '../dto/departmentEmployees.dto';

export class DepartmentEmployeesService {
    constructor(private api: ApiClient){}

    public async search(search?: IDepartmentEmployeeSearch){
        return this.api.get<SearchResult<IDepartmentEmployee>>('employees-on-object-departments', search);
    }

    public async create(payload: IDepartmentEmployeeCreate){
        return this.api.post<IDepartmentEmployee>('employees-on-object-departments', payload);
    }

    public async update(id: string, payload: IDepartmentEmployeeUpdate){
        return this.api.patch<IDepartmentEmployee>('employees-on-object-departments/' + id, payload);
    }

    public async delete(id: string){
        return this.api.delete<void>('employees-on-object-departments/' + id);
    }
}

export default new DepartmentEmployeesService(apiClient);