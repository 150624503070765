import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../dto/common.dto';
import { IServiceCreate, IService, IServiceUpdate, IServiceSearch } from '../dto/services.dto';

export class ServicesService {
    constructor(private api: ApiClient){}

    public async searchServices(search?: IServiceSearch){
        return this.api.get<SearchResult<IService>>('services', search);
    }

    public async createService(payload: IServiceCreate){
        return this.api.post<IService>('services', payload);
    }

    public async updateService(id: string, payload: IServiceUpdate){
        return this.api.patch<IService>('services/' + id, payload);
    }

    public async deleteService(id: string){
        return this.api.delete<void>('services/' + id);
    }
}

export default new ServicesService(apiClient);