import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../dto/common.dto';
import { IObjectCreate, IObject, IObjectSearch, IObjectUpdate } from '../dto/objects.dto';

export class ObjectsService {
    constructor(private api: ApiClient){}

    public async searchObjects(search?: IObjectSearch){
        return this.api.get<SearchResult<IObject>>('objects', search);
    }

    public async createObject(payload: IObjectCreate){
        return this.api.post<IObject>('objects', payload);
    }

    public async updateObject(id: string, payload: IObjectUpdate){
        return this.api.patch<IObject>('objects/' + id, payload);
    }

    public async deleteObject(id: string){
        return this.api.delete<void>('objects/' + id);
    }
}

export default new ObjectsService(apiClient);