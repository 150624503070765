import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { IServiceCreate, ServiceMeasureEnum, ServiceMeasureEnumLabels } from '../../API/dto/services.dto';
import { IServiceType } from '../../API/dto/serviceTypes.dto';

interface IProps {
    serviceTypes: IServiceType[],
    onCreate: (payload: IServiceCreate) => void
}

const ServicesNew: FC<IProps> = ({ serviceTypes, onCreate }) => {
    const [name, setName] = useState<string>('');
    const [serviceTypeId, setServiceTypeId] = useState<string>('');
    const [measure, setMeasure] = useState<ServiceMeasureEnum>(ServiceMeasureEnum.amount);

    const handleCreate = () => onCreate({ name, measure, serviceTypeId })

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Stack direction="row" flexWrap="wrap" gap="10px">
                <TextField
                    type="text"
                    variant="filled"
                    sx={{width:'300px'}}
                    required={true}
                    label='Название'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <FormControl variant='filled' sx={{width:'300px'}}>
                    <InputLabel>Тип услуги</InputLabel>
                    <Select value={serviceTypeId} onChange={e => setServiceTypeId(e.target.value)}>
                        {
                            serviceTypes.map(type => (
                                <MenuItem value={type.id} key={type.id}>
                                    {type.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl variant='filled' sx={{width:'300px'}}>
                    <InputLabel>Ед. измерения</InputLabel>
                    <Select value={measure} onChange={e => setMeasure(e.target.value as ServiceMeasureEnum)}>
                        {
                            Object.values(ServiceMeasureEnum).map(measure => <MenuItem value={measure} key={measure}>{ServiceMeasureEnumLabels[measure]}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </Stack>
            <Button sx={{ marginTop: '20px' }} 
                variant="outlined" 
                size="large" 
                onClick={handleCreate}
                disabled={!(name && measure)}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default ServicesNew;