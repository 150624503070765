import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { IEmployee } from '../../API/dto/employees.dto';
import { IService } from '../../API/dto/services.dto';
import { IServiceType } from '../../API/dto/serviceTypes.dto';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import employeesService from '../../API/services/employees.service';
import servicesService from '../../API/services/services.service';
import usePermissions from '../../hooks/usePermissions';
import EmployeeServicesItem from './EmployeeServicesItem';
import EmployeeServicesNew from './EmployeeServicesNew';

const EmployeeServices: FC = () => {
    const { employeeId } = useParams();
    const [employee, setEmployee] = useState<IEmployee>();
    const [services, setServices] = useState<IService[]>([]);
    const [showForm, setShowForm] = useState(false);

    const hasPermission = usePermissions();
    if(
        !hasPermission(GroupPermissionEnum.EmployeeRead) 
        || 
        !hasPermission(GroupPermissionEnum.ServiceRead)
        ||
        !hasPermission(GroupPermissionEnum.EmployeesOnServicesRead)
    ){
        return <Navigate to="/" replace />
    }

    useEffect(() => {
        employeesService.searchEmployees({ id: employeeId })
            .then(res => res.items[0])
            .then(setEmployee)
            .catch(console.log);
    }, [employeeId])

    useEffect(() => {
        servicesService.searchServices()
            .then(res => res.items)
            .then(setServices)
            .then(console.log)
    }, [])

    const createEmployeeService = (serviceId: string) => {
        if(!employee) return;
        const services = [ ...employee.services.map(service => service.serviceId), serviceId ];
        employeesService.updateEmployee(employee.id, { services })
            .then(setEmployee)
            .then(() => setShowForm(false))
            .catch(console.log)
    }

    const updateEmployeeService = (serviceId: string, payload: string) => {
        if(!employee) return;
        const services = employee.services.map(service => service.serviceId === serviceId ? payload : service.serviceId);
        employeesService.updateEmployee(employee.id, { services })
            .then(setEmployee)
            .catch(console.log)
    }

    const deleteEmployeeService = (serviceId: string) => {
        if(!employee) return;
        const services = employee.services.filter(service => service.serviceId !== serviceId).map(service => service.serviceId);
        employeesService.updateEmployee(employee.id, { services })
            .then(setEmployee)
            .catch(console.log)
    }

    if(!employee) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 450  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500  }}/>
        </Container>
    )

    const asignedServices = employee.services.map(({ service }) => service?.id);
    const availableServices = services.filter(service => !asignedServices.includes(service.id));
    const availableTypes = availableServices.reduce<IServiceType[]>((types, { serviceType }) => {
        if(serviceType && !types.find(type => type.id === serviceType.id)) {
            types.push(serviceType);
        }
        return types;
    }, []);

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Link to="/employees"><Typography fontSize={18}>Сотрудники</Typography></Link>
                <Typography color="black" fontSize={18}>Услуги сотрудника &quot;{ employee.lastName +' '+ employee.firstName }&quot;</Typography>
            </Breadcrumbs>

            {
                hasPermission(GroupPermissionEnum.EmployeesOnServicesEdit) && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v=>!v)}>
                            { showForm ? 'Закрыть' : 'Добавить услугу' }
                        </Button>
                        { 
                            showForm && <EmployeeServicesNew types={availableTypes} services={availableServices} onCreate={createEmployeeService} />
                        }
                    </Box>
                )
            }

            {
                employee.services.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 500, padding: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{minWidth: 250 }}>Тип</TableCell>
                                    <TableCell align="center" sx={{minWidth: 250 }}>Услуга</TableCell>
                                    {
                                        hasPermission(GroupPermissionEnum.EmployeesOnServicesEdit) && <TableCell align="center" sx={{minWidth: 120, width: 120 }}></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    employee.services.map((employeeService) => (
                                        <EmployeeServicesItem
                                            types={availableTypes}
                                            services={availableServices} 
                                            employeeService={employeeService}
                                            canEdit={hasPermission(GroupPermissionEnum.EmployeesOnServicesEdit)} 
                                            onUpdate={payload => updateEmployeeService(employeeService.serviceId, payload)}
                                            onDelete={() => deleteEmployeeService(employeeService.serviceId)}
                                            key={employeeService.serviceId}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default EmployeeServices;