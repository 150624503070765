import dayjs, { Dayjs } from 'dayjs';
import { IContract } from '../API/dto/contracts.dto';
import { ServiceMeasureEnumAbbr } from '../API/dto/services.dto';
import { ITracker } from '../API/dto/tracker.dto';
import { dateFormat } from '../utils/dateFormat';


interface CounterpartyPaymentsData {
    amount: number;
    price: number;
}

interface ServiceCounterpartyPaymentsData extends CounterpartyPaymentsData {
    cost: number;
}

export default class CounterpartyPaymentsTable {
    private servicePaymentsPerDate = new Map<string, ServiceCounterpartyPaymentsData>();
    private totalServicePayments = new Map<string, ServiceCounterpartyPaymentsData>();
    private totalDatePayments = new Map<string, CounterpartyPaymentsData>();
    private totalPayments: CounterpartyPaymentsData = { amount: 0, price: 0 };
    
    constructor(
        private contracts: IContract[], 
        private trackers: ITracker[]
    ) {
        let serviceDateData: ServiceCounterpartyPaymentsData;
        let totalServiceData: ServiceCounterpartyPaymentsData;
        let totalDateData: CounterpartyPaymentsData;
        this.contracts.forEach(contract => {
            if(contract.costForCounterpartyFixed){
                this.totalPayments.price += contract.costForCounterpartyFixed;
            } else {
                contract.services.forEach(({ service, costForCounterparty}) => {
                    totalServiceData = this.getTotalServicePayments(contract.id, service.id);
                    totalServiceData.cost = costForCounterparty;
                });
            }
        });
        this.trackers.forEach(tracker => {
            serviceDateData = this.getServicePaymentsPerDate(tracker.contractId, tracker.serviceId, tracker.dateAt);
            totalServiceData = this.getTotalServicePayments(tracker.contractId, tracker.serviceId);
            totalDateData = this.getTotalDatePayments(tracker.dateAt);
            serviceDateData.amount += tracker.amount;
            totalServiceData.amount += tracker.amount;
            totalDateData.amount += tracker.amount;
            if(!serviceDateData.cost) serviceDateData.cost = totalServiceData.cost;
            serviceDateData.price += tracker.amount * totalServiceData.cost;
            totalServiceData.price += tracker.amount * totalServiceData.cost;
            totalDateData.price += tracker.amount * totalServiceData.cost;
            this.totalPayments.amount += tracker.amount;
            this.totalPayments.price += tracker.amount * totalServiceData.cost;
        });
    }

    public getServicePaymentsPerDate(contractId: string, serviceId: string, date: string | Dayjs) {
        const key = [contractId, serviceId, dayjs(date).format(dateFormat)].join('-');
        let data = this.servicePaymentsPerDate.get(key) ;
        if(!data) {
            data = { cost: 0, amount: 0, price: 0 };
            this.servicePaymentsPerDate.set(key, data);
        }
        return data;
    }

    public getTotalServicePayments(contractId: string, serviceId: string) {
        const key = [contractId, serviceId].join('-');
        let data = this.totalServicePayments.get(key) ;
        if(!data) {
            data = { cost: 0, amount: 0, price: 0 };
            this.totalServicePayments.set(key, data);
        }
        return data;
    }

    public getTotalDatePayments(date: string | Dayjs) {
        const key = dayjs(date).format(dateFormat);
        let data = this.totalDatePayments.get(key) ;
        if(!data) {
            data = { amount: 0, price: 0 };
            this.totalDatePayments.set(key, data);
        }
        return data;
    }

    public getTotalPayments() {
        return this.totalPayments;
    }

    public getCSVTable(dates: string[] | Dayjs[]){
        const table: Array<Array<string | number>>  = [];
        let row: Array<string | number> = [ 'Название услуги' ];
        dates.forEach(date => row.push(dayjs(date).format('DD MMM YY')));
        table.push(row);
        
        this.contracts.forEach(contract => {
            table.push([ contract.name ]);
            contract.services.forEach(({ service, contractId, serviceId }) => {
                const serviceData = this.getTotalServicePayments(contractId, serviceId);
                row = [service.name + ` ${serviceData.amount} ${ServiceMeasureEnumAbbr[service.measure]} | ${serviceData.price} ₽`  ];
                dates.forEach(date => {
                    const data = this.getServicePaymentsPerDate(contractId, serviceId, date);
                    row.push(data ? `${data.amount} ${ServiceMeasureEnumAbbr[service.measure]} | ${data.price} ₽` : '')
                })
                table.push(row);
            })
        })
    
        row = [ 'Итого за день' ];
        dates.forEach(date => {
            const dateData = this.getTotalDatePayments(date);
            row.push(dateData ? dateData.price + ' ₽' : '');
        })
        table.push(row);
        table.push([ 'Итого за период', this.getTotalPayments() + ' ₽' ])
        return table;    
    }
}