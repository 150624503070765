import { Button, IconButton, TableCell, TableRow, TextField } from '@mui/material';
import { FC, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IObject, IObjectUpdate } from '../../API/dto/objects.dto';
import { Link } from 'react-router-dom';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';


interface IProps {
    object: IObject,
    onUpdate: (payload: IObjectUpdate) => void,
    onDelete: () => void,
}

const ServicesItem: FC<IProps> = ({ object, onUpdate, onDelete }) => {
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [name, setName] = useState<string>(object.name);

    const hasPermission = usePermissions()

    const handleUpdate = () => {
        onUpdate({ name });
        setEditMode(false);
    }

    if(hasPermission(GroupPermissionEnum.ObjectEdit) && isEditMode) return (
        <TableRow>
            <TableCell align="center">
                <TextField
                    type="text"
                    variant="outlined"
                    size='small'
                    fullWidth
                    required={true}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </TableCell>
            <TableCell align="center">
                <Link to={`/objects/${object.id}/units`}>
                    <Button variant='outlined'>{object.objectDepartments?.length || 0}</Button>
                </Link>
            </TableCell>
            <TableCell align="center">
                <Link to={`/objects/${object.id}/contracts`}>
                    <Button variant='outlined'>{object.contracts?.length || 0}</Button>
                </Link>
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={() => setEditMode(false)}>
                    <CancelIcon/>
                </IconButton>
                <IconButton onClick={handleUpdate} disabled={!name}>
                    <CheckCircleIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    )
    
    return (
        <TableRow>
            <TableCell align="center">{object.name}</TableCell>
            {
                hasPermission(GroupPermissionEnum.ContractRead) && (
                    <TableCell align="center">
                        <Link to={`/objects/${object.id}/contracts`}>
                            <Button variant='outlined'>{object.contracts?.length || 0}</Button>
                        </Link>
                    </TableCell>
                )
            }
            {
                hasPermission(GroupPermissionEnum.ObjectEdit) && (
                    <TableCell align="center">
                        <IconButton onClick={() => setEditMode(true)}>
                            <EditIcon/>
                        </IconButton>
                        <IconButton onClick={onDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default ServicesItem;