import React from 'react';
import { Box, Breadcrumbs, Container, Stack, Typography } from '@mui/material';

import ServiceTrackersTable from './ServiceTrackersTable';
import SearchEmployee from '../SearchEmployee';
import SearchObject from '../SearchObject';
import SearchDate from '../SearchDate';
import useSearchState from '../../hooks/useSearchState';
import { Link, Navigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { dateFormat } from '../../utils/dateFormat';
import SearchContract from '../SearchContract';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';

const ServiceTrackers: React.FC = () => {
    const [employeeId, setEmployeeId] = useSearchState('employeeId');
    const [objectId, setObjectId] = useSearchState('objectId');
    const [contractId, setContractId] = useSearchState('contractId');
    const [dateFrom, setDateFrom] = useSearchState('dateFrom', dayjs().subtract(1, 'week').format(dateFormat));
    const [dateTo, setDateTo] = useSearchState('dateTo', dayjs().format(dateFormat));

    const hasPermission = usePermissions();
    if(!hasPermission(GroupPermissionEnum.ServiceTrackerRead)){
        return <Navigate to="/" replace />
    }

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Табель</Typography>
            </Breadcrumbs>

            <Stack direction='row' gap='10px' flexWrap='wrap' marginTop='40px'>
                <SearchEmployee employeeId={employeeId} setEmployeeId={setEmployeeId} />
                <SearchObject objectId={objectId} setObjectId={setObjectId} />
                {
                    employeeId && objectId && dateFrom && dateTo && <SearchContract contractId={contractId} setContractId={setContractId} objectId={objectId} />
                }
                <SearchDate date={dateFrom} setDate={setDateFrom}/>
                <SearchDate date={dateTo} setDate={setDateTo}/>
            </Stack>

            <Box sx={{ marginTop:'40px'}}>
                {
                    employeeId && objectId && dateFrom && dateTo && <ServiceTrackersTable employeeId={employeeId} objectId={objectId} contractId={contractId} dateFrom={dateFrom} dateTo={dateTo} />
                }
            </Box>
        </Container>
    )
}

export default ServiceTrackers;