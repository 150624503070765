import React, { useState, useEffect } from 'react';
import { Box, Breadcrumbs, Button, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Link, Navigate, useParams } from 'react-router-dom';
import EmployeeDocumentsItem from './EmployeeDocumentsItem';
import EmployeeDocumentsNew from './EmployeeDocumentsNew';
import { IEmployee } from '../../API/dto/employees.dto';
import employeesService from '../../API/services/employees.service';
import documentsService from '../../API/services/documents.service';
import { IDocument } from '../../API/interfaces/document';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';

const EmployeeDocuments = () => {
    const { employeeId } = useParams();
    const [employee, setEmployee] = useState<IEmployee>();
    const [EmployeeDocuments, setEmployeeDocuments] = useState<IDocument[]>([]);
    const [showForm, setShowForm] = useState(false);

    const hasPermission = usePermissions();
    if(
        !hasPermission(GroupPermissionEnum.EmployeeRead) 
        || 
        !hasPermission(GroupPermissionEnum.DocumentRead)
    ){
        return <Navigate to="/" replace />
    }

    const searchEmployee = () => {
        employeesService.searchEmployees({ id: employeeId })
            .then(res => res.items[0])
            .then(employee => {
                setEmployee(employee);
            })
            .catch(console.log);
    }

    useEffect(searchEmployee, [employeeId])

    const searchDocuments = () => {
        documentsService.search({ employeeId: employeeId })
        .then((r) => {setEmployeeDocuments(r.items); setShowForm(false)})
        .catch((err) => console.log(err))
    }

    useEffect(searchDocuments, []);

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >

            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Link to="/employees"><Typography fontSize={18}>Сотрудники</Typography></Link>
                <Typography color="black" fontSize={18}>Документы сотрудника &quot;{employee?.lastName + ' ' + employee?.firstName}&quot;</Typography>
            </Breadcrumbs>

            {
                hasPermission(GroupPermissionEnum.DocumentEdit) && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v => !v)}>
                            {showForm ? 'Закрыть' : 'Добавить документ'}
                        </Button>
                        {showForm && <EmployeeDocumentsNew employeeId={employeeId as string} refresh={searchDocuments}/>}
                    </Box>
                )
            }

            <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 500, padding: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ minWidth: 250 }}>Наименование документа</TableCell>
                            <TableCell align="center" sx={{ minWidth: 250 }}>файл</TableCell>
                            <TableCell align="center" sx={{ minWidth: 250 }}>Данные</TableCell>
                            <TableCell align="center" sx={{ minWidth: 250 }}>комментарий</TableCell>
                            {
                               hasPermission(GroupPermissionEnum.DocumentEdit) && <TableCell align="center" sx={{ minWidth: 120, width: 120 }}></TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            EmployeeDocuments.map((document) => (
                                <EmployeeDocumentsItem 
                                    document={document}
                                    canEdit={hasPermission(GroupPermissionEnum.DocumentEdit)}
                                    refresh={searchDocuments}
                                    key={document.id}
                                />
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}

export default EmployeeDocuments