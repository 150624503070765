export enum GroupPermissionEnum {
    EmployeeRead = 'Чтение сотрудников',
    EmployeeEdit = 'Изменение сотрудников',
    PositionRead = 'Чтение должностей',
    PositionEdit = 'Изменение должностей',
    EmployeesOnGroupsRead = 'Чтение связей сотрудников с группами',
    EmployeesOnGroupsEdit = 'Изменение связей сотрудников с группами',
    GroupRead = 'Чтение групп',
    GroupEdit = 'Изменение групп',
    EmployeesOnServicesRead = 'Чтение связей сотрудников с услугами',
    EmployeesOnServicesEdit = 'Изменение связей сотрудников с услугами',
    ServiceTypeRead = 'Чтение типов услуг',
    ServiceTypeEdit = 'Изменение типов услуг',
    ServiceRead = 'Чтение услуг',
    ServiceEdit = 'Изменение услуг',
    DocumentRead = 'Чтение документов',
    DocumentEdit = 'Изменение документов',
    ObjectRead = 'Чтение объектов',
    ObjectEdit = 'Изменение объектов',
    ContractRead = 'Чтение подразделений',
    ContractEdit = 'Изменение подразделений',
    ContractsOnServicesRead = 'Чтение связей подразделений с услугами',
    ContractsOnServicesEdit = 'Изменение связей подразделений с услугами',
    ContractsOnEmployeesRead = 'Чтение связей подразделений с сотрудниками',
    ContractsOnEmployeesEdit = 'Изменение связей подразделений с сотрудниками',
    ServiceTrackerRead = 'Чтение трекеров',
    ServiceTrackerEdit = 'Изменение трекеров',
    AnalyticsSalary = 'Просмотр отчетов по зарплате',
    AnalyticsCounterpartyCost = 'Просмотр отчетов по стоимости для заказчика'
}