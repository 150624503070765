import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../dto/common.dto';
import { IEmployeePositionCreate, IEmployeePosition, IEmployeePositionSearch, IEmployeePositionUpdate } from '../dto/employeePositions.dto';

export class PositionsService {
    constructor(private api: ApiClient){}

    public async search(search?: IEmployeePositionSearch){
        return this.api.get<SearchResult<IEmployeePosition>>('positions', search);
    }

    public async create(payload: IEmployeePositionCreate){
        return this.api.post<IEmployeePosition>('positions', payload);
    }

    public async update(id: string, payload: IEmployeePositionUpdate){
        return this.api.patch<IEmployeePosition>('positions/' + id, payload);
    }

    public async delete(id: string){
        return this.api.delete<void>('positions/' + id);
    }
}

export default new PositionsService(apiClient);