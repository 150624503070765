import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { SignupDto } from '../API/dto/auth.dto';
import authService from '../API/services/auth.service';

interface IAuthContext {
    isAuth: boolean;
    signIn: (cred: SignupDto) => Promise<void>;
    signOut: () => void;
}

export const AuthContext = createContext({} as IAuthContext);

export const useAuth = () => useContext(AuthContext);

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuth, setIsAuth] = useState(Boolean(localStorage.getItem('access-token')));

    async function signIn(cred: SignupDto) {
        return authService.login(cred)
            .then(() => setIsAuth(true))
    }

    function signOut(){
        localStorage.removeItem('access-token')
        setIsAuth(false)
    }

    return (
        <AuthContext.Provider value={{ isAuth, signIn, signOut }}>
           { children } 
        </AuthContext.Provider>
    )
}

export default AuthProvider;




