import { FC, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import contractsService from '../API/services/contracts.service';
import { IContract } from '../API/dto/contracts.dto';

interface IProps {
    contractId: string,
    setContractId: (value: string) => void,
    objectId: string
}

const SearchContract: FC<IProps> = ({ contractId, setContractId, objectId }) => {
    const [contracts, setContracts] = useState<IContract[]>();

    useEffect(() => {
            contractsService.searchContracts({ objectId })
            .then(res => res.items)
            .then(setContracts)
            .catch(console.log)
    }, [objectId])
    

    return (
        <FormControl variant='filled' style={{ width: 270 }}>
            <InputLabel id="demo-simple-select-label">Подразделение</InputLabel>
            <Select
                sx={{ height: '56px' }}
                size='small'
                value={contractId}
                onChange={(e) => setContractId(e.target.value)}
            >
                <MenuItem value={''}>Не выбрано</MenuItem>
                {
                    contracts?.map((contract) => (
                        <MenuItem
                            value={contract.id}
                            key={contract.id}>
                            {contract.name}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default SearchContract;