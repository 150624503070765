import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../dto/common.dto';
import { IContractEmployee, IContractEmployeeCreate, IContractEmployeeSearch, IContractEmployeeUpdate } from '../interfaces/contractEmployees';

export class ContractEmployeesService {
    constructor(private api: ApiClient){}

    public async search(search?: IContractEmployeeSearch){
        return this.api.get<SearchResult<IContractEmployee>>('contracts-on-employees', search);
    }

    public async create(payload: IContractEmployeeCreate){
        return this.api.post<IContractEmployee>('contracts-on-employees', payload);
    }

    public async update(id: string, payload: IContractEmployeeUpdate){
        return this.api.patch<IContractEmployee>('contracts-on-employees/' + id, payload);
    }

    public async delete(id: string){
        return this.api.delete<void>('contracts-on-employees/' + id);
    }
}

export default new ContractEmployeesService(apiClient);