import { TableRow, TableCell, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { FC } from 'react';
import { IEmployeeGroup } from '../../API/dto/employeeGroups.dto';

interface IProps {
    group: IEmployeeGroup;
    canEdit: boolean;
    onDelete: (id: string) => void;
}

const EmployeeGroupsItem: FC<IProps> = ({ group, canEdit, onDelete }) => {

    const ondelete = () => { onDelete(group.id) }

    return (
        <TableRow>
            <TableCell align="center">{group.group?.name}</TableCell>
            {
                canEdit && (
                    <TableCell align="center">
                        <IconButton onClick={ondelete}><DeleteIcon /></IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default EmployeeGroupsItem;