import { Box, Button, Stack, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { IObjectCreate } from '../../API/dto/objects.dto';

interface IProps {
    onCreate: (payload: IObjectCreate) => void
}

const GroupsNew: FC<IProps> = ({ onCreate }) => {
    const [name, setName] = useState<string>('');

    const handleCreate = () => onCreate({ name })

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Stack direction="row" flexWrap="wrap" gap="10px">
                <TextField
                    type="text"
                    variant="filled"
                    sx={{width:'300px'}}
                    required={true}
                    label='Название'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </Stack>
            <Button sx={{ marginTop: '20px' }} 
                variant="outlined" 
                size="large" 
                onClick={handleCreate}
                disabled={!name}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default GroupsNew;