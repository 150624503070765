
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { IService } from '../../API/dto/services.dto';
import { IServiceType } from '../../API/dto/serviceTypes.dto';

interface IProps {
    types: IServiceType[],
    services: IService[],
    onCreate: (serviceId: string) => void,
}

const EmployeeServicesNew: FC<IProps> = ({ onCreate, services, types }) => {
    const [typeId, setTypeId] = useState<string>('');
    const typeServices =  services.filter(service => !typeId || !service.serviceTypeId ||service.serviceTypeId === typeId);

    const [serviceId, setServiceId] = useState<string>('');
    
    const handleCreate = () => onCreate(serviceId)

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Stack direction="row" flexWrap="wrap" gap="10px">
                <FormControl variant='filled' required sx={{ width: '300px' }}>
                    <InputLabel>Тип</InputLabel>
                    <Select value={typeId} onChange={e => { setTypeId(e.target.value); setServiceId('') }} >
                        <MenuItem value="" key="">Не выбрано</MenuItem>
                        {
                            types.map((type) => (
                                <MenuItem value={type.id} key={type.id}>
                                    {type.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl variant='filled' required sx={{ width: '300px' }}>
                    <InputLabel>Услуга</InputLabel>
                    <Select value={serviceId} onChange={e => setServiceId(e.target.value)} >
                        {
                            typeServices.map((service) => (
                                <MenuItem value={service.id} key={service.id}>
                                    {service.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Stack>
            <Button sx={{ marginTop: '20px' }} 
                variant="outlined" 
                size="large" 
                onClick={handleCreate}
                disabled={!serviceId}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default EmployeeServicesNew;