import { FC } from 'react';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider, ruRU } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { dateFormat } from '../utils/dateFormat';

interface IProps {
    date: string,
    setDate: (value: string) => void
}

const SearchDate: FC<IProps> = ({ date, setDate }) => {
    return (
        <LocalizationProvider adapterLocale="ru-RU" dateAdapter={AdapterDayjs} localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText} >
            <DatePicker
                format='DD.MM.YYYY'
                value={dayjs(date)}
                onAccept={(date) => setDate(date?.format(dateFormat) || '')}
                disableFuture 
            />
        </LocalizationProvider>
    )
}
export default SearchDate;