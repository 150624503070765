import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IGroup } from '../../API/dto/groups.dto';
import employeesService from '../../API/services/employees.service';
import { IEmployee } from '../../API/dto/employees.dto';
import EmployeeGroupsNew from './EmployeeGroupsNew';
import groupsService from '../../API/services/groups.service';
import employeeGroupsService from '../../API/services/employeeGroups.service';
import EmployeeGroupsItem from './EmployeeGroupsItem';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import usePermissions from '../../hooks/usePermissions';

const EmployeeGroups = () => {
    const { employeeId } = useParams();
    const [showForm, setShowForm] = useState(false);
    const [employee, setEmployee] = useState<IEmployee>();
    const [groups, setGroups] = useState<IGroup[]>();

    const hasPermission = usePermissions();
    if(
        !hasPermission(GroupPermissionEnum.GroupRead) 
        ||
        !hasPermission(GroupPermissionEnum.EmployeesOnGroupsRead) 
        || 
        !hasPermission(GroupPermissionEnum.EmployeeRead)
    ){
        return <Navigate to="/" replace />
    }

    const searchEmployee = () => {
        setShowForm(false);
        employeesService.searchEmployees({ id: employeeId })
            .then(r => setEmployee(r.items[0]))
            .catch(er => console.log(er))
    }

    const searchEmployeeGroups = () => {
        groupsService.searchGroups({})
            .then(r => setGroups(r.items))
            .catch(er => console.log(er))
    }

    useEffect(searchEmployeeGroups, [])
    useEffect(searchEmployee, [employeeId])

    const handleCreate = (payload: string) => {
        employeeGroupsService.create({
            employeeId: String(employeeId),
            groupId: payload
        })
            .then(searchEmployee)
            .catch(er => console.log(er))
    }

    const onDelete = (id: string) => {
        employeeGroupsService.delete(id)
        .then(searchEmployee)
        .catch(er => console.log(er))
    }

    const assignedGroups = employee?.groups.map(({ groupId }) => groupId);
    const aviableGroups = groups?.filter(group => !assignedGroups?.includes(group.id))

    { if (!groups) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 450 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500 }} />
        </Container>
    )
    }
    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Link to="/employees"><Typography fontSize={18}>Сотрудники</Typography></Link>
                <Typography color="black" fontSize={18}>Группы сотрудника &quot;{employee ? `${employee.firstName} ${employee.lastName}` : ''}&quot;</Typography>
            </Breadcrumbs>
            
            {
                hasPermission(GroupPermissionEnum.EmployeesOnGroupsEdit) && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v => !v)}>
                            {showForm ? 'Закрыть' : 'Добавить группу'}
                        </Button>
                        {
                            showForm && <EmployeeGroupsNew groups={aviableGroups as IGroup[]} handleCreate={handleCreate} />
                        }
                    </Box>
                )
            }

            {
                <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 500, padding: '20px', }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" sx={{ minWidth: 250 }}>Сотрудник</TableCell>
                                {
                                    hasPermission(GroupPermissionEnum.EmployeesOnGroupsEdit) && <TableCell align="center" sx={{ minWidth: 120, width: 120 }}></TableCell>
                                }
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                employee?.groups.map(group => (
                                    <EmployeeGroupsItem 
                                        group={group} 
                                        canEdit={hasPermission(GroupPermissionEnum.EmployeesOnGroupsEdit)} 
                                        onDelete={onDelete} 
                                        key={group.id}
                                    />
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>   
            }
        </Container>
    )
}

export default EmployeeGroups;