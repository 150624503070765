import { FC, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { IEmployee } from '../API/dto/employees.dto';
import employeesService from '../API/services/employees.service';

interface IProps {
    employeeId: string,
    setEmployeeId: (value: string) => void
}

const SearchEmployee: FC<IProps> = ({ employeeId, setEmployeeId }) => {
    const [employees, setEmployees] = useState<IEmployee[]>();

    useEffect(() => {
            employeesService.searchEmployees()
                .then(res => res.items)
                .then(setEmployees)
                .catch(console.log)
    }, [])

    return (
        <FormControl variant='filled' style={{ width: 270 }}>
            <InputLabel id="demo-simple-select-label">Работник</InputLabel>
            <Select
                sx={{ height: '56px' }}
                size='small'
                value={employeeId}
                onChange={(e) => setEmployeeId(e.target.value)}
            >
                {
                    employees?.map((employee) => (
                        <MenuItem
                            value={employee.id}
                            key={employee.id}>
                            {employee.lastName + ' ' + employee.firstName + ' ' + employee.middleName}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default SearchEmployee;