import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { IDepartmentEmployee } from '../../API/dto/departmentEmployees.dto';
import { IEmployee } from '../../API/dto/employees.dto';
import { IObject } from '../../API/dto/objects.dto';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import departmentEmployeesService from '../../API/services/departmentEmployees.service';
import employeesService from '../../API/services/employees.service';
import objectsService from '../../API/services/objects.service';
import usePermissions from '../../hooks/usePermissions';
import EmployeeDepartmentsItem from './EmployeeDepartmentsItem';
import EmployeeDepartmentsNew from './EmployeeDepartmentsNew';

const EmployeeDepartments: FC = () => {
    const { employeeId } = useParams();
    const [employee, setEmployee] = useState<IEmployee>();
    const [employeeDepartments, setEmployeeDepartments] = useState<IDepartmentEmployee[]>([]);
    const [objects, setObjects] = useState<IObject[]>([]);
    const [showForm, setShowForm] = useState(false);

    const hasPermission = usePermissions();
    if(
        !hasPermission(GroupPermissionEnum.EmployeeRead) 
        || 
        !hasPermission(GroupPermissionEnum.ContractRead)
    ){
        return <Navigate to="/" replace />
    }

    const searchEmployee = () => {
        employeesService.searchEmployees({ id: employeeId })
            .then(res => res.items[0])
            .then(employee => {
                setEmployee(employee);
                //setEmployeeDepartments(employee.objectDepartments);
                console.log(setEmployeeDepartments);
            })
            .catch(console.log);
    }

    const createEmployeeDepartment = (objectDepartmentId: string) => {
        employeeId && departmentEmployeesService.create({ employeeId, objectDepartmentId })
            .then(searchEmployee)
            .catch(console.log);
    }

    const updateEmployeeDepartment = (employeeDepartmentId: string, objectDepartmentId: string) => {
        departmentEmployeesService.update(employeeDepartmentId, { objectDepartmentId })
            .then(searchEmployee) 
            .catch(console.log);
    }

    const deleteEmployeeDepartment = (employeeDepartmentId: string) => {
        departmentEmployeesService.delete(employeeDepartmentId)
            .then(searchEmployee)
            .catch(console.log)
    }

    useEffect(searchEmployee, [employeeId])

    useEffect(() => {
        objectsService.searchObjects()
            .then(res => res.items)
            .then(setObjects)
            .catch(console.log);
    }, [])

    if(!employee) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 450  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500  }}/>
        </Container>
    )

    const asignedDepartments = employeeDepartments.map(({ objectDepartmentId }) => objectDepartmentId);
    const availableObjects = objects.reduce<IObject[]>((objects, object) => {
        const availableDepartments = object.objectDepartments?.filter((department) => !asignedDepartments.includes(department.id));
        if(availableDepartments && availableDepartments.length > 0) {
            objects.push({ ...object, objectDepartments: availableDepartments });
        }
        return objects;
    }, [])

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Link to="/employees"><Typography fontSize={18}>Сотрудники</Typography></Link>
                <Typography color="black" fontSize={18}>Подразделения сотрудника &quot;{ employee.lastName +' '+ employee.firstName }&quot;</Typography>
            </Breadcrumbs>

            <Box sx={{ marginTop: '40px' }}>
                <Button variant="contained" size="large" onClick={() => setShowForm(v=>!v)}>
                    { showForm ? 'Закрыть' : 'Добавить подразделение' }
                </Button>
                { showForm && <EmployeeDepartmentsNew objects={availableObjects} onCreate={createEmployeeDepartment} /> }
            </Box>

            {
                employeeDepartments.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 500, padding: '20px', }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ minWidth: 250 }}>Объект</TableCell>
                                    <TableCell align="center" sx={{ minWidth: 250 }}>Подразделение</TableCell>
                                    <TableCell align="center" sx={{ minWidth: 120, width: 120 }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    employeeDepartments.map((department) => (
                                        <EmployeeDepartmentsItem
                                            objects={availableObjects} 
                                            employeeDepartment={department}
                                            onUpdate={payload => updateEmployeeDepartment(department.id, payload)}
                                            onDelete={() => deleteEmployeeDepartment(department.id)}
                                            key={department.id}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default EmployeeDepartments;