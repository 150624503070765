import { Box, Button, Checkbox, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { IEmployeeCreate, EmployeeStatusEnum, EmployeePaymentTypeEnum, EmployeeEmploymentTypeEnum } from '../../API/dto/employees.dto';
import { IEmployeePosition } from '../../API/dto/employeePositions.dto';

interface IProps {
    positions: IEmployeePosition[],
    onCreate: (payload: IEmployeeCreate) => void
}

const EmployeesNew: FC<IProps> = ({ positions, onCreate }) => {
    const [firstName, setFirstName] = useState<string>('');
    const [middleName, setMiddleName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [citizenship, setСitizenship] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');

    const [status, setStatus] = useState<EmployeeStatusEnum>(EmployeeStatusEnum.Employee);
    const [position, setPosition] = useState<{ id?: string }>({});

    const [canAuth, setAuthAbility] = useState<boolean>(false);

    const [paymentType] = useState<EmployeePaymentTypeEnum>(EmployeePaymentTypeEnum.cash);
    const [employmentType] = useState<EmployeeEmploymentTypeEnum>(EmployeeEmploymentTypeEnum.someType);

    const handleCreate = () => onCreate({ firstName, middleName, lastName, phoneNumber, citizenship, email, status, employmentType, paymentType, position, canAuth })

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Stack direction="row" flexWrap="wrap" gap="10px">
                <TextField
                    type="text"
                    variant="filled"
                    sx={{ width: '300px' }}
                    required={true}
                    label='Фамилия'
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <TextField
                    type="text"
                    variant="filled"
                    sx={{ width: '300px' }}
                    required={true}
                    label='Имя'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                    type="text"
                    variant="filled"
                    sx={{ width: '300px' }}
                    label='Отчество'
                    value={middleName}
                    onChange={(e) => setMiddleName(e.target.value)}
                />
                <TextField
                    type="text"
                    variant="filled"
                    sx={{ width: '300px' }}
                    label='Гражданство'
                    value={citizenship}
                    onChange={(e) => setСitizenship(e.target.value)}
                />
                <TextField
                    type="text"
                    variant="filled"
                    sx={{ width: '300px' }}
                    label='Телефон'
                    required={true}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <TextField
                    type="text"
                    variant="filled"
                    sx={{ width: '300px' }}
                    label='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <FormControl variant='filled' sx={{ width: '300px' }}>
                    <InputLabel>Должность</InputLabel>
                    <Select value={position.id} onChange={e => setPosition({ id: e.target.value })}>
                        {
                            positions.map(position => (
                                <MenuItem value={position.id} key={position.id}>
                                    {position.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl variant='filled' required sx={{ width: '300px' }} >
                    <InputLabel>Статус</InputLabel>
                    <Select value={status} onChange={e => setStatus(e.target.value as EmployeeStatusEnum)} >
                        {
                            Object.values(EmployeeStatusEnum).map((status) => (
                                <MenuItem value={status} key={status}>
                                    {status}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Stack>
            <Box sx={{ marginTop: '10px', display: 'flex', alignItems: 'center'}}>
                <Checkbox checked={canAuth} onChange={() => setAuthAbility(!canAuth)} />
                <InputLabel>Может авторизироваться</InputLabel>
            </Box>
            <Button sx={{ marginTop: '20px' }}
                variant="outlined"
                size="large"
                onClick={handleCreate}
                disabled={!(firstName && lastName && phoneNumber && status)}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default EmployeesNew;