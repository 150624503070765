

export class ApiClient {
    constructor (){
        this.baseURL = process.env.REACT_APP_API_URL || 'http://cleaning-track.backend.dev.iddqd.pro/';
        this.accessToken = localStorage.getItem('access-token');
    }

    private baseURL: string;
    private accessToken: string | null;

    public setToken(token: string){
        this.accessToken = token;
        localStorage.setItem('access-token', token);
    }

    public setFormData(payload: object){
        const formData = new FormData();
        Object.entries(payload).forEach(([key, val]) => formData.append(key, val instanceof File ? val : JSON.stringify(val)));
        return formData;
    }

    public async get<R>(url: string, query?: object):Promise<R>{
        return fetch(this.baseURL + url + this.getQueryString(query), {
            method: 'GET',
            headers: this.getJsonHeaders()
        }).then(this.getJsonResponse)
    }

    public async post<R>(url: string, payload?: object | FormData): Promise<R>{
        return fetch(this.baseURL + url, {
            method: 'POST',
            body: payload instanceof FormData ? payload : JSON.stringify(payload),
            headers: payload instanceof FormData ? this.getStandartHeaders() : this.getJsonHeaders()
        }).then(this.getJsonResponse)
    }

    public async put<R>(url: string, payload?: object): Promise<R>{
        return fetch(this.baseURL + url, {
            method: 'PUT',
            body: payload instanceof FormData ? payload : JSON.stringify(payload),
            headers: payload instanceof FormData ? this.getStandartHeaders() : this.getJsonHeaders()
        }).then(this.getJsonResponse)
    }

    public async patch<R>(url: string, payload?: object): Promise<R>{
        return fetch(this.baseURL + url, {
            method: 'PATCH',
            body: payload instanceof FormData ? payload : JSON.stringify(payload),
            headers: payload instanceof FormData ? this.getStandartHeaders() : this.getJsonHeaders()
        }).then(this.getJsonResponse)
    }

    public async delete<R>(url: string):Promise<R>{
        return fetch(this.baseURL + url, {
            method: 'DELETE',
            headers: this.getJsonHeaders()
        }).then(this.getStandartResponse)
    }

    public async download(url: string, fileName: string = new Date().toString()):Promise<void>{
        return fetch(this.baseURL + url, {
            method: 'GET',
            headers: this.getJsonHeaders()
        })
            .then(this.getFileResponse)
            .then(blob => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
    }

    private getStandartHeaders(){
        return {
            'Authorization': 'Bearer ' + this.accessToken
        }
    }

    private getJsonHeaders(){
        return {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
        }
    }

    private getStandartResponse(res: Response){
        if(res.ok){
            return res.body as any;
        }
        throw new Error(res.statusText);
    }

    private getJsonResponse(res: Response){
        if(res.ok){
            return res.json();
        }
        throw new Error(res.statusText);
    }

    private getFileResponse(res: Response){
        if(res.ok){
            return res.blob();
        }
        throw new Error(res.statusText);
    }

    public getQueryString(queries?: object){
        if(!queries) return '';
        return '?' + Object.entries(queries).map(query => query.join('=')).join('&');
    }
    
}

export default new ApiClient();