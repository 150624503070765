import { IconButton, TableCell, TableRow, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { IServiceType, IServiceTypeUpdate } from '../../API/dto/serviceTypes.dto';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


interface IProps {
    type: IServiceType,
    canEdit: boolean,
    onUpdate: (payload: IServiceTypeUpdate) => void,
    onDelete: () => void,
}

const ServiceTypesItem: FC<IProps> = ({ type, canEdit,  onUpdate, onDelete }) => {
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [name, setName] = useState<string>(type.name);
    
    const handleUpdate = () => onUpdate({ name })

    if(canEdit && isEditMode) return (
        <TableRow>
            <TableCell align="center">
                <TextField
                    size='small'
                    type="text"
                    variant="outlined"
                    required={true}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={() => setEditMode(false)}>
                    <CancelIcon/>
                </IconButton>
                <IconButton onClick={handleUpdate}>
                    <CheckCircleIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    )
    
    return (
        <TableRow>
            <TableCell align="center">{type.name}</TableCell>
            {
                canEdit && (
                    <TableCell align="center">
                        <IconButton onClick={() => setEditMode(true)}>
                            <EditIcon/>
                        </IconButton>
                        <IconButton onClick={onDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default ServiceTypesItem;