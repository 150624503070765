import { IContract } from '../API/dto/contracts.dto';
import { ServiceMeasureEnumAbbr } from '../API/dto/services.dto';
import { ITracker } from '../API/dto/tracker.dto';

type ContractServiceData = {
    cost: number,
    amount: number,
    price: number,
}

const defaultData: ContractServiceData = {
    cost: 0,
    amount: 0,
    price: 0
}

export default class CounterpartyPaymentsSummTable {
    private contractServiceDataMap = new Map<string, ContractServiceData>()
    private totalPrice = 0;

    constructor(
        private contracts: IContract[], 
        private trackers: ITracker[]
    ) {

        let data: ContractServiceData;
        this.contracts.forEach(contract => {
            if(contract.costForCounterpartyFixed){
                this.totalPrice += contract.costForCounterpartyFixed;
            } else {
                contract.services.forEach(({ service, costForCounterparty}) => {
                    data = this.getContractServiceData(contract.id, service.id);
                    data.cost = costForCounterparty;
                })
            }
        });
        this.trackers.forEach(tracker => {
            data = this.getContractServiceData(tracker.contractId, tracker.serviceId);
            data.amount += tracker.amount;
            data.price += tracker.amount * data.cost;
            this.totalPrice += tracker.amount * data.cost;
        });
    }

    public getContractServiceData(contractId: string, serviceId: string) {
        let data = this.contractServiceDataMap.get(contractId + '-' + serviceId);
        if(!data) {
            data = { ...defaultData };
            this.contractServiceDataMap.set(contractId + '-' + serviceId, data);
        }
        return data;
    }

    public getTotalPrice() {
        return this.totalPrice;
    }

    public getCSVTable(){
        const table: Array<Array<string | number>>  = [];
        table.push([ 'Название услуги', 'Количество', 'Цена за ед. измерения', 'Цена' ]);
        
        this.contracts.forEach(contract => {
            table.push([contract.name]);
            contract.services.forEach(({ service, contractId, serviceId }) => {
                const serviceData = this.getContractServiceData(contractId, serviceId);
                table.push([ 
                    service.name, 
                    `${serviceData?.amount} ${ServiceMeasureEnumAbbr[service.measure]}`,
                    `${serviceData?.cost} ₽/${ServiceMeasureEnumAbbr[service.measure]}`,
                    `${serviceData?.price} ₽`
                ]);
            })
        })
    
        table.push([ 
            'Итого', 
            `${this.getTotalPrice()} ₽`
        ]);
        return table;    
    }
}