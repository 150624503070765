import { IEntity, IEntitySearch } from './common.dto';
import { IServiceType } from './serviceTypes.dto';

export interface IService extends IEntity {
    name: string;
    measure: ServiceMeasureEnum;
    serviceTypeId?: string;
    serviceType?: IServiceType;
}

export interface IServiceSearch extends IEntitySearch {
    name?: string;
    measure?: ServiceMeasureEnum;
    serviceTypeId?: string;
}

export interface IServiceCreate {
    name: string;
    measure: ServiceMeasureEnum;
    serviceTypeId?: string;
}

export interface IServiceUpdate {
    name?: string;
    measure?: ServiceMeasureEnum;
    serviceTypeId?: string;
}

export enum ServiceMeasureEnum {
    amount = 'amount',
    squareMetres = 'squareMetres',
    hours = 'hours',
    shifts = 'shifts',
    credits = 'credits',
}

export const ServiceMeasureEnumLabels: Record<ServiceMeasureEnum, string> =  {
    [ServiceMeasureEnum.amount]: 'штуки',
    [ServiceMeasureEnum.squareMetres]: 'кв.метры',
    [ServiceMeasureEnum.hours]: 'часы',
    [ServiceMeasureEnum.shifts]: 'смены',
    [ServiceMeasureEnum.credits]: 'кредиты'
}

export const ServiceMeasureEnumAbbr: Record<ServiceMeasureEnum, string> =  {
    [ServiceMeasureEnum.amount]: 'шт',
    [ServiceMeasureEnum.squareMetres]: 'м²',
    [ServiceMeasureEnum.hours]: 'ч',
    [ServiceMeasureEnum.shifts]: 'см',
    [ServiceMeasureEnum.credits]: 'кр'
}