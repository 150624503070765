import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../dto/common.dto';
import { IEmployeeCreate, IEmployee, IEmployeeSearch, IEmployeeUpdate } from '../dto/employees.dto';

export class EmployeesService {
    constructor(private api: ApiClient){}

    public async getProfile(){
        return this.api.get<IEmployee>('employees/profile');
    }

    public async searchEmployees(query?: IEmployeeSearch){
        return this.api.get<SearchResult<IEmployee>>('employees', query);
    }

    public async createEmployee(payload: IEmployeeCreate){
        return this.api.post<IEmployee>('employees', payload);
    }

    public async updateEmployee(id: string, payload: IEmployeeUpdate){
        return this.api.patch<IEmployee>('employees/' + id, payload);
    }

    public async deleteEmployee(id: string){
        return this.api.delete<void>('employees/' + id);
    }
}

export default new EmployeesService(apiClient);