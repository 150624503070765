import React, { useState } from 'react';
import { IconButton, Select, Button, MenuItem, Input, TextField, TableCell, TableRow, FormControl } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DocumentTypeEnum } from '../../API/enums/documentType';
import { IDocument } from '../../API/interfaces/document';
import documentsService from '../../API/services/documents.service';

interface IDocumentProps {
    document: IDocument;
    canEdit: boolean;
    refresh: () => void;
}

const EmployeeDocumentsItem = ({ document, canEdit, refresh }: IDocumentProps) => {
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [documentValue, setDocumentValue] = useState<DocumentTypeEnum>(document.type);
    const [editCommentValue, setEditCommentValue] = useState('');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore 
    const [serial, setSerial] = useState<string>(document.properties.serial || '');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
     // @ts-ignore 
    const [number, setNumber] = useState<string>(document.properties.number || '');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore 
    const [date, setDate] = useState<string>(document.properties.date || '');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore 
    const [name, setName] = useState<string>(document.properties.name || '');
    const [file, setFile] = useState<File>();

    const handleUpdateDocument = () => {
        if (documentValue === DocumentTypeEnum.inn) {
            documentsService.update(document.id, {
                type: documentValue,
                comment: editCommentValue,
                properties: {
                    number: number
                },
                file: file
            })
                .then(() => { 
                    refresh(); 
                    setSerial('');
                    setNumber('');
                    setDate('');
                    setName('');
                    setDocumentValue(document.type);
                    setEditMode(false) })
                .catch(er => console.log(er));
        }
        if (documentValue === DocumentTypeEnum.passport) {
            documentsService.update(document.id, {
                type: documentValue,
                comment: editCommentValue,
                properties: {
                    serial: serial,
                    number: number,
                    date: date
                }
            })
                .then(() => { 
                    refresh(); 
                    setSerial('');
                    setNumber('');
                    setDate('');
                    setName('');
                    setDocumentValue(document.type);
                    setEditMode(false) })
                .catch(er => console.log(er));
        }
        if (documentValue === DocumentTypeEnum.snils) {
            documentsService.update(document.id, {
                type: documentValue,
                comment: editCommentValue,
                properties: {
                    number: number,
                    date: date
                }
            })
                .then(() => { 
                    refresh(); 
                    setSerial('');
                    setNumber('');
                    setDate('');
                    setName('');
                    setDocumentValue(document.type);
                    setEditMode(false)
                 })
                .catch(er => console.log(er));
        }
        if (documentValue === DocumentTypeEnum.untyped) {
            documentsService.update(document.id, {
                type: documentValue,
                comment: editCommentValue,
                properties: {
                    name: name
                }
            })
                .then(() => { 
                    refresh(); 
                    setSerial('');
                    setNumber('');
                    setDate('');
                    setName('');
                    setDocumentValue(document.type);
                    setEditMode(false) })
                .catch(er => console.log(er));
        }
    }

    const handleDeleteDocument = (id: string) => {
        const result =
            confirm
                ('Вы действительно хотите удалить этот документ' + '?')
        if (result) {
            documentsService.delete(id)
                .then(() => refresh())
                .catch(er => console.log(er));
        }
    }

    const handleOpenEidtMode = () => {
        
        setEditCommentValue(document.comment as string);
        setEditMode(true);
    }

    const handleDownloadDocument = () => {
        documentsService.download(document.file)
            .then()
            .catch(er => console.log(er));
    }

    const onFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files as FileList;
        setFile(files[0]);
    }

    if (canEdit && isEditMode) return (
        <TableRow>
            <TableCell align='center'>
                <Select sx={{ minWidth: '150px' }} value={documentValue}
                    onChange={(e) => setDocumentValue(e.target.value as DocumentTypeEnum)}>
                    <MenuItem value={DocumentTypeEnum.passport}>
                        {'Паспорт'}
                    </MenuItem>
                    <MenuItem value={DocumentTypeEnum.snils}>
                        {'СНИЛС'}
                    </MenuItem>
                    <MenuItem value={DocumentTypeEnum.inn}>
                        {'ИНН'}
                    </MenuItem>
                    <MenuItem value={DocumentTypeEnum.untyped}>
                        {'Другое'}
                    </MenuItem>
                </Select>
            </TableCell>
            <TableCell align='center'>
                <FormControl variant='filled' required sx={{ width: '300px' }}>
                    <p style={{ margin: 0, fontFamily: 'Roboto' }}>Загрузить файл</p>
                    <Button variant="contained" color="info" sx={{ width: '300px', margin: '0' }} >
                        <Input type='file' sx={{ width: '200px', height: '100%' }} required onChange={onFileUpload} 
                        />
                    </Button>
                </FormControl>
            </TableCell>
            <TableCell align='center'>
                {documentValue === DocumentTypeEnum.passport ?
                    <FormControl variant='filled' required sx={{ width: '250px' }}>
                        <TextField
                            type="text"
                            variant="filled"
                            required
                            sx={{ width: '250px', marginBottom: '10px' }}
                            label='Серия'
                            value={serial}
                            onChange={(e) => setSerial(e.target.value)}
                        />
                        <TextField
                            type="text"
                            variant="filled"
                            required
                            sx={{ width: '250px', marginBottom: '10px' }}
                            label='Номер'
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                        />
                        <TextField
                            type="text"
                            variant="filled"
                            required
                            sx={{ width: '250px' }}
                            label='Дата выдачи'
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                    </FormControl> : <></>}
                {documentValue === DocumentTypeEnum.inn ?
                    <FormControl variant='filled' required sx={{ width: '300px' }}>
                        <TextField
                            type="text"
                            variant="filled"
                            required
                            sx={{ width: '300px' }}
                            label='Номер'
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                        />
                    </FormControl> : <></>}
                {documentValue === DocumentTypeEnum.snils ?
                    <FormControl variant='filled' required sx={{ width: '250px' }}>
                        <TextField
                            type="text"
                            variant="filled"
                            required
                            sx={{ width: '250px', marginBottom: '10px' }}
                            label='Номер'
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                        />
                        <TextField
                            type="text"
                            variant="filled"
                            required
                            sx={{ width: '250px', marginBottom: '10px' }}
                            label='Дата выдачи'
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                    </FormControl> : <></>}
                {documentValue === DocumentTypeEnum.untyped ?
                    <FormControl variant='filled' required sx={{ width: '300px' }}>
                        <TextField
                            type="text"
                            variant="filled"
                            required
                            sx={{ width: '300px' }}
                            label='Название документа'
                            value={name}
                            onChange={(e) => { setName(e.target.value) }}
                        />
                    </FormControl> : <></>}
            </TableCell>
            <TableCell align='center'>
                <TextField
                    type="text"
                    variant="filled"
                    sx={{ width: '300px' }}
                    label='Комментарий'
                    value={editCommentValue}
                    onChange={(e) => setEditCommentValue(e.target.value)}
                />
            </TableCell>
            <TableCell align='center'>
                <IconButton onClick={handleUpdateDocument}>
                    <CheckCircleIcon />
                </IconButton>
                <IconButton onClick={() => setEditMode(false)}>
                    <CancelIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )

    return (
        <TableRow>
            <TableCell align='center'>
                {document.type === DocumentTypeEnum.passport ? 'Паспорт' : ''}
                {document.type === DocumentTypeEnum.inn ? 'ИНН' : ''}
                {document.type === DocumentTypeEnum.snils ? 'СНИЛС' : ''}
                {document.type === DocumentTypeEnum.untyped ? 'Другое' : ''}
            </TableCell>
            <TableCell align='center'>
                <Button variant="outlined" onClick={handleDownloadDocument}>скачать</Button>
            </TableCell>
            <TableCell align='center' >
                {document.type === DocumentTypeEnum.passport ?
                    'серия: ' + document.properties.serial + ' ' +
                    'номер: ' + document.properties.number + ' ' +
                    'дата: ' + document.properties.date
                    : ''}

                {document.type === DocumentTypeEnum.inn ?
                    'номер: ' + document.properties.number + ' '
                    : ''}

                {document.type === DocumentTypeEnum.snils ?
                    'номер: ' + document.properties.number + ' ' +
                    'дата: ' + document.properties.date
                    : ''}

                {document.type === DocumentTypeEnum.untyped ?
                    'наименование: ' + document.properties.name
                    : ''}
            </TableCell>
            <TableCell align='center'>
                {document.comment}
            </TableCell>
            {
                canEdit && (
                    <TableCell align='center'>
                        <IconButton onClick={handleOpenEidtMode}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteDocument(document.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default EmployeeDocumentsItem;