import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { Box, Breadcrumbs, Button, Container, FormControl, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import useSearchState from '../../hooks/useSearchState';
import SearchEmployee from '../SearchEmployee';
import SearchObject from '../SearchObject';
import SearchDate from '../SearchDate';
import { Link, Navigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { dateFormat } from '../../utils/dateFormat';
import { ITracker } from '../../API/dto/tracker.dto';
import contractsService from '../../API/services/contracts.service';
import { IContract } from '../../API/dto/contracts.dto';
import { ServiceMeasureEnumAbbr } from '../../API/dto/services.dto';
import trackerService from '../../API/services/tracker.service';
import EmployeePaymentsData from '../../tables/EmployeePaymentsData';
import generateCSVLink from '../../utils/generateCSVLink';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';

const border = '1px solid rgba(224, 224, 224, 1)';

const EmployeePayments: FC = () => {
    const [employeeId, setEmployeeId] = useSearchState('employeeId');
    const [objectId, setObjectId] = useSearchState('objectId');
    const [contractId, setContractId] = useSearchState('contractId');
    const [dateFrom, setDateFrom] = useSearchState('dateFrom', dayjs().subtract(1, 'week').format(dateFormat));
    const [dateTo, setDateTo] = useSearchState('dateTo', dayjs().format(dateFormat));

    const [trackers, setTrackers] = useState<ITracker[]>([]);
    const [contracts, setContracts] = useState<IContract[]>([]);

    const hasPermission = usePermissions();
    if(!hasPermission(GroupPermissionEnum.AnalyticsSalary)){
        return <Navigate to="/" replace />
    }

    const filteredContracts = useMemo(() => contractId ? contracts.filter(contract => contract.id == contractId) : contracts, [contracts, contractId]);

    const dates = useMemo(()=>{
        const dates = [];
        let date = dayjs(dateFrom);
        while (date.isBefore(dayjs(dateTo)) || date.isSame(dayjs(dateTo))) {
            dates.push(date);
            date = date.add(1, 'day');
        }
        return dates;
    }, [dateFrom, dateTo])

    const table = useMemo(() => new EmployeePaymentsData(filteredContracts, trackers), [filteredContracts, trackers] )

    useEffect(() => {
        contractsService.searchContracts({ objectId })
            .then(res => res.items)
            .then(setContracts)
            .catch(console.log);
    }, [objectId])

    useEffect(() => {
        trackerService.searchTrackers({
            employeeId,
            objectId,
            dateFrom: dayjs(dateFrom).format(dateFormat),
            dateTo: dayjs(dateTo).format(dateFormat),
          })
              .then(res => res.items)
              .then(setTrackers)
              .catch(console.log);
    }, [employeeId, objectId, dateFrom, dateTo])

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Зарплата сотрудникам</Typography>
            </Breadcrumbs>

            <Stack direction='row' gap='10px' flexWrap='wrap' marginTop='40px'>
                <SearchEmployee employeeId={employeeId} setEmployeeId={setEmployeeId} />
                <SearchObject objectId={objectId} setObjectId={setObjectId} />
                <FormControl variant='filled' style={{ width: 270 }} disabled={!objectId}>
                    <InputLabel id="demo-simple-select-label">Подразделение</InputLabel>
                    <Select
                        sx={{ height: '56px' }}
                        size='small'
                        value={contractId}
                        onChange={(e) => setContractId(e.target.value)}
                    >
                        <MenuItem value="">Не выбрано</MenuItem>
                        {
                            contracts?.map((contract) => (
                                <MenuItem
                                    value={contract.id}
                                    key={contract.id}>
                                    {contract.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <SearchDate date={dateFrom} setDate={setDateFrom}/>
                <SearchDate date={dateTo} setDate={setDateTo}/>
            </Stack>
            {
                Boolean(contracts.length) && (
                    <Fragment>
                        <TableContainer component={Paper} sx={{ marginTop: '40px' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell rowSpan={2} sx={{ minWidth: '180px', border }}>
                                            <Typography fontSize={16}>Название услуги</Typography>  
                                        </TableCell>
                                        <TableCell rowSpan={2} sx={{ border }} align="center">
                                            <Typography fontSize={16}>Цена за единицу</Typography>  
                                        </TableCell>
                                        {
                                            Object.entries(dates.reduce((months, date) => {
                                                const month = date.format('MMMM YYYY')
                                                months[month] = months[month] + 1 || 1;
                                                return months;
                                            }, {} as Record<string, number>)).map(([month, count]) => (
                                                <TableCell sx={{ border: '1px solid rgba(0,0,0, .2)' }}
                                                    key={month} align='center' size='small'
                                                    colSpan={count}
                                                >
                                                    {month}
                                                </TableCell>
                                            ))
                                        }
                                        <TableCell rowSpan={2} sx={{ border }} align="center">
                                            <Typography fontSize={16}>Итого в ед. изм.</Typography>
                                        </TableCell>
                                        <TableCell rowSpan={2} sx={{ border }} align="center">
                                            <Typography fontSize={16}>Итого сумма руб</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {
                                            dates.map((date) => (
                                                <TableCell 
                                                    align='center'
                                                    sx={{ minWidth: '60px', border: '1px solid rgba(0,0,0, .2)', padding: '10px 5px' }}
                                                    key={date.format(dateFormat)} 
                                                >
                                                    <Typography fontSize={14}>{date.format('DD dd')}</Typography>
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        filteredContracts.map(contract => (
                                            <Fragment key={contract.id}>
                                                {
                                                    !contractId && (
                                                        <TableRow>
                                                            <TableCell colSpan={dates.length + 4} align="center" sx={{ border }}>
                                                                <Typography fontSize={16}>{contract.name}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                                {
                                                    contract.services.map(({ service, contractId, serviceId, costForEmployee }) => {
                                                        const totalServicePayments = table.getTotalServicePayments(contractId, serviceId);
                                                        return (
                                                            <TableRow key={service.id} hover={true}>
                                                                <TableCell sx={{ border }}>{service.name}, {ServiceMeasureEnumAbbr[service.measure]}</TableCell>
                                                                <TableCell sx={{ border }} align="center">{costForEmployee}</TableCell>
                                                                { 
                                                                    dates.map((date) => {
                                                                        const data = table.getServicePaymentsPerDate(contractId, serviceId, date);
                                                                        return (
                                                                            <TableCell key={date.format(dateFormat)} sx={{ width: '50px', border: '1px solid rgba(0,0,0, .2)'}} align="center">
                                                                                { Boolean(data.amount) && data.amount }
                                                                            </TableCell>
                                                                        )
                                                                    })
                                                                }
                                                                <TableCell sx={{ border}} align="center">
                                                                    <Typography fontSize={16}>{ Boolean(totalServicePayments.amount) && totalServicePayments.amount }</Typography>  
                                                                </TableCell>
                                                                <TableCell sx={{ border}} align="center">
                                                                    <Typography fontSize={16}>{ Boolean(totalServicePayments.amount) && totalServicePayments.price }</Typography>  
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </Fragment>
                                        ))
                                    }

                                    <TableRow>
                                        <TableCell sx={{ border }} colSpan={2}>
                                            <Typography fontSize={16}>Итого за день в ед.изм</Typography>  
                                        </TableCell>
                                        { 
                                            dates.map((date) => {
                                                const data = table.getTotalDatePayments(date);
                                                return (
                                                    <TableCell key={date.format(dateFormat)} sx={{  border }} align="center">
                                                        <Typography fontSize={16}>{ data.amount || '' }</Typography>  
                                                    </TableCell>
                                                )
                                            })
                                        }
                                        <TableCell sx={{ border }} align="center">
                                            <Typography fontSize={18}>{table.getTotalAmounts()}</Typography>  
                                        </TableCell>
                                        <TableCell sx={{ border }} align="center">
                                            <Typography fontSize={18}>{table.getTotalPayments()}</Typography>   
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box sx={{marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <Typography fontSize={20}>Всего за период {table.getTotalPayments()} ₽</Typography>
                            <a href={generateCSVLink(table.getCSVTable(dates))} download="EmployeePayments.csv">
                                <Button variant="contained">Скачать</Button>
                            </a>
                        </Box>
                    </Fragment>
                )
            }
        </Container>
    )
}

export default EmployeePayments;