import { Button, IconButton, TableCell, TableRow, TextField } from '@mui/material';
import { FC, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom';
import { IGroup, IGroupUpdate } from '../../API/dto/groups.dto';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';


interface IProps {
    group: IGroup,
    onUpdate: (id: string, payload: IGroupUpdate) => void,
    onDelete: (id: string) => void,
}

const GroupsItem: FC<IProps> = ({ group, onUpdate, onDelete }) => {
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [name, setName] = useState<string>(group.name || '');
    const hasPermission = usePermissions();

    const handleUpdate = () => {
        onUpdate(group.id, { name });
        setEditMode(false);
    }

    if (hasPermission(GroupPermissionEnum.GroupEdit) && isEditMode) return (
        <TableRow>
            <TableCell align="center">
                <TextField
                    type="text"
                    variant="outlined"
                    size='small'
                    fullWidth
                    required={true}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </TableCell>
            <TableCell align="center">
                <Link to={`/groups/${group.id}/permissions`}>
                    <Button variant='outlined'>{group.permissions.length || 0}</Button>
                </Link>
            </TableCell>
            <TableCell align="center">
                <Link to={`/groups/${group.id}/employees`}>
                    <Button variant='outlined'>{group.employees?.length || 0}</Button>
                </Link>
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={() => setEditMode(false)}>
                    <CancelIcon />
                </IconButton>
                <IconButton onClick={handleUpdate} disabled={!name}>
                    <CheckCircleIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )

    return (
        <TableRow>
            <TableCell align="center">{group.name}</TableCell>

            <TableCell align="center">
                <Link to={`/groups/${group.id}/permissions`}>
                    <Button variant='outlined'>{group.permissions.length || 0}</Button>
                </Link>
            </TableCell>
            {
                hasPermission(GroupPermissionEnum.EmployeesOnGroupsRead) && (
                    <TableCell align="center">
                        <Link to={`/groups/${group.id}/employees`}>
                            <Button variant='outlined'>{group.employees?.length || 0}</Button>
                        </Link>
                    </TableCell>
                )
            }
            {
                hasPermission(GroupPermissionEnum.GroupEdit) && (
                    <TableCell align="center">
                        <IconButton onClick={() => setEditMode(true)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => onDelete(group.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default GroupsItem;