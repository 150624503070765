import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../dto/common.dto';
import { ITrackerCreate, ITrackerSearch, ITracker, ITrackerUpdate } from '../dto/tracker.dto';

export class TrackersService {
    constructor(private api: ApiClient){}

    public async searchTrackers(search?: ITrackerSearch){
        return this.api.get<SearchResult<ITracker>>('service-trackers', search);
    }

    public async createTracker(payload: ITrackerCreate){
        return this.api.post<ITracker>('service-trackers', payload);
    }

    public async updateTracker(id: string, payload: ITrackerUpdate){
        return this.api.patch<ITracker>('service-trackers/' + id, payload);
    }

    public async deleteTracker(id: string){
        return this.api.delete<void>('service-trackers/' + id);
    }
}

export default new TrackersService(apiClient);