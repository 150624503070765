import { FC, useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { IObject } from '../../API/dto/objects.dto';
import { IContract, IContractCreate, IContractUpdate } from '../../API/dto/contracts.dto';
import objectsService from '../../API/services/objects.service';
import contractsService from '../../API/services/contracts.service';
import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import ObjectContractsItem from './ObjectsContractsItem';
import ObjectContractsNew from './ObjectsContractsNew';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';

const ObjectContracts: FC = () => {
    const { objectId } = useParams();
    const [object, setObject] = useState<IObject>();
    const [contracts, setContracts] = useState<IContract[]>([]);
    const [showForm, setShowForm] = useState(false);

    const hasPermission = usePermissions();
    if(
        !hasPermission(GroupPermissionEnum.ObjectRead) 
        || 
        !hasPermission(GroupPermissionEnum.ContractRead)
    ){
        return <Navigate to="/" replace />
    }

    useEffect(() => {
        objectsService.searchObjects({ id: objectId })
            .then(res => res.items[0])
            .then(setObject)
            .catch(console.log)
        contractsService.searchContracts({ objectId })
            .then(res => res.items)
            .then(setContracts)
            .catch(console.log)
    }, [objectId])

    if(!object) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 450  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500  }}/>
        </Container>
    )

    const createContract = (payload: IContractCreate) => {
            contractsService.createContract(payload)
                .then(res => {
                    setContracts(contracts => [...contracts, res]);
                    setShowForm(false);
                })
                .catch(console.log);
    }

    const updateContract = (id: string, payload: IContractUpdate) => {
            contractsService.updateContract(id, payload)
                .then(res => setContracts(contracts => contracts.map(contract => contract.id === id ? res : contract)))
                .catch(console.log);
    }
        
    const deleteContract = (id: string) => {
            contractsService.deleteContract(id)
                .then(() => setContracts(contracts => contracts.filter(contract => contract.id !== id)))
                .catch(console.log);
    }

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Link to="/objects"><Typography fontSize={18}>Объекты</Typography></Link>
                <Typography color="black" fontSize={18}>Подразделения объекта &quot;{object.name}&quot;</Typography>
            </Breadcrumbs>
            {
                hasPermission(GroupPermissionEnum.ContractEdit) && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v=>!v)}>
                            { showForm ? 'Закрыть' : 'Добавить подразделение' }
                        </Button>
                        { showForm && <ObjectContractsNew objectId={object.id} onSubmit={createContract} /> }
                    </Box>
                )
            }

            {
                contracts.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 500, padding: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" width="180px">Название</TableCell>
                                    <TableCell align="center" width="180px">Подразделение</TableCell>
                                    <TableCell align="center" width="180px">Контрагент</TableCell>
                                    <TableCell align="center" width="180px">Регион</TableCell>
                                    <TableCell align="center" width="180px">Адрес</TableCell>
                                    <TableCell align="center" width="180px">фиксированная цена</TableCell>
                                    {
                                        hasPermission(GroupPermissionEnum.ContractsOnServicesRead) && <TableCell align="center">Услуги</TableCell>
                                    }
                                    {
                                        hasPermission(GroupPermissionEnum.ContractsOnEmployeesRead) && <TableCell align="center">Сотрудники</TableCell>
                                    }
                                    {
                                        hasPermission(GroupPermissionEnum.ContractEdit) && <TableCell width="120px"></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    contracts.map(contract => (
                                        <ObjectContractsItem 
                                            contract={contract} 
                                            onUpdate={payload => updateContract(contract.id, payload)} 
                                            onDelete={() => deleteContract(contract.id)}
                                            key={contract.id}
                                        />

                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default ObjectContracts;