import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField} from '@mui/material';
import { FC, useState, useEffect } from 'react';
import { IContractServiceCreate } from '../../API/dto/contractServices.dto';
import { IService } from '../../API/dto/services.dto';
import { IServiceType } from '../../API/dto/serviceTypes.dto';
import { IContract } from '../../API/dto/contracts.dto';

interface IProps {
    onCreate: (payload: Omit<IContractServiceCreate, 'contractId'>) => void,
    services: IService[],
    types: IServiceType[],
    contract: IContract;
}

const ContractServicesNew: FC<IProps> = ({ onCreate, services, types, contract }) => {
    const [typeId, setTypeId] = useState<string>('');
    const typeServices = services.filter(service => !typeId || !service.serviceTypeId || service.serviceTypeId === typeId);
    const [serviceId, setServiceId] = useState<string>('');
    const [checkedFixedCost, setCheckedFixedCost] = useState(false);
    const [costForCounterparty, setCostForCounterparty] = useState<number>(0);
    const [costForEmployee, setCostForEmployee] = useState<number>(0);

    const handleCreate = () => onCreate({ serviceId, costForCounterparty, costForEmployee })

    useEffect(() => {
        if (contract.costForCounterpartyFixed) {
            setCheckedFixedCost(true);
            setCostForCounterparty(contract.costForCounterpartyFixed);
            setCostForEmployee(contract.costForCounterpartyFixed);
        }
    }, [contract])

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Stack direction="row" flexWrap="wrap" gap="10px">
                <FormControl variant='filled' fullWidth sx={{ width: '300px' }}>
                    <InputLabel id="service-label">Тип</InputLabel>
                    <Select labelId="service-label" value={typeId} onChange={e => { setTypeId(e.target.value); setServiceId(''); }} fullWidth >
                        <MenuItem value="" key="">Не выбрано</MenuItem>
                        {
                            types.map((type) => (
                                <MenuItem value={type.id} key={type.id}>
                                    {type.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl variant='filled' fullWidth required sx={{ width: '300px' }}>
                    <InputLabel id="service-label">Услуга</InputLabel>
                    <Select labelId="service-label" value={serviceId} onChange={e => setServiceId(e.target.value)} fullWidth >
                        {
                            typeServices.map((service) => (
                                <MenuItem value={service.id} key={service.id}>
                                    {service.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                <TextField
                    label="Цена по договору"
                    type="number"
                    variant="filled"
                    value={costForCounterparty}
                    disabled={checkedFixedCost}
                    onChange={e => setCostForCounterparty(+e.target.value)}
                    fullWidth
                    required
                    sx={{ width: '300px' }}
                />
                <TextField
                    label={'Цена для сотрудников'}
                    type="number"
                    variant="filled"
                    value={costForEmployee}
                    onChange={e => {setCostForEmployee(+e.target.value)}}
                    fullWidth
                    required
                    sx={{ width: '300px' }}
                />
            </Stack>
            <Button sx={{ marginTop: '20px' }} variant="outlined" size='large' onClick={handleCreate}>
                Добавить
            </Button>
        </Box>
    )
}

export default ContractServicesNew;