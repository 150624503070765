import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { IEmployee } from '../../API/dto/employees.dto';
import employeesService from '../../API/services/employees.service';
import { IGroup } from '../../API/dto/groups.dto';
import groupsService from '../../API/services/groups.service';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import { IEmployeeGroup } from '../../API/dto/employeeGroups.dto';
import employeeGroupsService from '../../API/services/employeeGroups.service';
import GroupEmployeesNew from './GroupEmployeesNew';
import GroupEmployeesItem from './GroupEmployeesItem';

const GroupEmployees: FC = () => {
    const { groupId } = useParams();
    const [group, setGroup] = useState<IGroup>();
    const [groupEmployees, setGroupEmployees] = useState<IEmployeeGroup[]>([]);
    const [employees, setEmployees] = useState<IEmployee[]>([]);
    const [showForm, setShowForm] = useState(false);

    const hasPermission = usePermissions();
    if(
        !hasPermission(GroupPermissionEnum.GroupRead) 
        ||
        !hasPermission(GroupPermissionEnum.EmployeesOnGroupsRead) 
        || 
        !hasPermission(GroupPermissionEnum.EmployeeRead)
    ){
        return <Navigate to="/" replace />
    }

    if(!groupId) return null;

    const searchGroup = () => {
        groupsService.searchGroups({ id: groupId })
            .then(r => r.items[0])
            .then(setGroup)
            .catch(console.log);
    }

    const searchGroupEmployees = () => {
        employeeGroupsService.search({ groupId })
            .then(r => r.items)
            .then(setGroupEmployees)
            .catch(console.log);
    }

    const searchEmployees = () => {
        employeesService.searchEmployees()
            .then(r => r.items)
            .then(setEmployees)
            .catch(console.log)
    }

    const createGroupEmployee = (employeeId: string) => {
        employeeGroupsService.create({ groupId, employeeId })
            .then(searchGroupEmployees)
            .catch(console.log);
    }

    const deleteGroupEmployee = (groupEmployeeId: string) => {
        employeeGroupsService.delete(groupEmployeeId)
            .then(searchGroupEmployees)
            .catch(console.log);
    }

    useEffect(() => {
        searchGroup()
        searchGroupEmployees()
    }, [groupId])

    useEffect(() => {
        searchEmployees()
    }, [])

    if (!group || !groupEmployees || !employees) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 450 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500 }} />
        </Container>
    )
    
    const asignedEmployeeIds = groupEmployees.map(({ employeeId }) => employeeId);
    const availableEmployees = employees.filter(employee => !asignedEmployeeIds.includes(employee.id));

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Link to="/groups"><Typography fontSize={18}>Группы</Typography></Link>
                <Typography color="black" fontSize={18}>Сотрудники группы &quot;{group ? group.name : ''}&quot;</Typography>
            </Breadcrumbs>

            {
                hasPermission(GroupPermissionEnum.EmployeesOnGroupsEdit) && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v => !v)}>
                            {showForm ? 'Закрыть' : 'Добавить сотрудника'}
                        </Button>
                        { showForm && <GroupEmployeesNew employees={availableEmployees} onCreate={createGroupEmployee} />}
                    </Box>
                )
            }

            {
                groupEmployees.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 500, padding: '20px', }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ minWidth: 250 }}>Фамилия Имя</TableCell>
                                    {
                                        hasPermission(GroupPermissionEnum.EmployeesOnGroupsEdit) && (
                                            <TableCell align="center" sx={{ minWidth: 120, width: 120 }}></TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    groupEmployees.map((groupEmployee) => (
                                        <GroupEmployeesItem 
                                            key={groupEmployee.id}
                                            groupEmployee={groupEmployee}
                                            deleteGroupEmployee={deleteGroupEmployee}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default GroupEmployees;