import { useEffect, useMemo, useState } from 'react';
import { IEmployeePosition } from '../../API/dto/employeePositions.dto';
import { ITracker } from '../../API/dto/tracker.dto';
import { Breadcrumbs, Container, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import positionsService from '../../API/services/positions.service';
import trackerService from '../../API/services/tracker.service';
import dayjs from 'dayjs';
import { dateFormat } from '../../utils/dateFormat';
import useSearchState from '../../hooks/useSearchState';
import SearchDate from '../SearchDate';

const borderLeft = '1px solid rgba(224, 224, 224, 1)';

const PositionAmountsPerDay = () => {
    const [dateFrom, setDateFrom] = useSearchState('dateFrom', dayjs().subtract(1, 'week').format(dateFormat));
    const [dateTo, setDateTo] = useSearchState('dateTo', dayjs().format(dateFormat));
    const [positions, setPositions] = useState<IEmployeePosition[]>();
    const [trackers, setTrackers] = useState<ITracker[]>();

    useEffect(() => {
        Promise.all([
            positionsService.search(),
            trackerService.searchTrackers({ dateFrom, dateTo })
        ]).then(([positions, trackers]) => {
            setPositions(positions.items);
            setTrackers(trackers.items);
        }).catch(console.log)
    }, [dateTo, dateFrom])

    const dateRange = useMemo(()=>{
        const dates = [];
        let date = dayjs(dateFrom);
        while (date.isBefore(dayjs(dateTo)) || date.isSame(dayjs(dateTo))) {
            dates.push(date);
            date = date.add(1, 'day');
        }
        return dates;
    }, [dateFrom, dateTo])

    const { positionDateAmounts, totalDateAmounts, totalPositionAmounts, totalAmount } = useMemo(() => {
        let position: string;
        let date: string;
        let positionDate: string;
        let amount: number;
        let positionAmount: number;
        let dateAmount: number;
        let totalAmount = 0;

        const positionDateAmounts = new Map<string, number>();
        const totalPositionAmounts = new Map<string, number>();
        const totalDateAmounts = new Map<string, number>();

        trackers?.forEach(tracker => {
            if(tracker.employee.positionId){
                position = tracker.employee.positionId;
                date = dayjs(tracker.dateAt).format(dateFormat);
                positionDate = position + '/' + date;
                amount = positionDateAmounts.get(positionDate) || 0;
                positionAmount = totalPositionAmounts.get(position) || 0;
                dateAmount = totalDateAmounts.get(date) || 0;
    
                amount += tracker.amount;
                positionAmount += tracker.amount;
                dateAmount += tracker.amount;
                totalAmount += tracker.amount;

                positionDateAmounts.set(positionDate, amount);
                totalPositionAmounts.set(position, positionAmount);
                totalDateAmounts.set(date, dateAmount);
            }
        })

        return {
            positionDateAmounts,
            totalPositionAmounts,
            totalDateAmounts,
            totalAmount
        }
    }, [trackers])
    
    if(!trackers || !positions) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500  }}/>
        </Container>
    )
    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Сверка часы</Typography>
            </Breadcrumbs>

            <Stack direction='row' gap='10px' flexWrap='wrap' marginTop='40px'>
                <SearchDate date={dateFrom} setDate={setDateFrom}/>
                <SearchDate date={dateTo} setDate={setDateTo}/>
            </Stack>

            <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                <Typography fontSize={18} fontWeight="600">Должность</Typography>
                            </TableCell>
                            {
                                dateRange.map(date => {
                                    return (
                                        <TableCell align="center" sx={{ borderLeft }} key={date.format('DD dd')}>
                                            <Typography fontSize={18} fontWeight="600">{date.format('DD dd')}</Typography>
                                        </TableCell>
                                    )
                                })
                            }
                            <TableCell align="center" sx={{ borderLeft }}>
                                <Typography fontSize={18} fontWeight="600">Всего, ч</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            positions.map(position => {
                                return (
                                    <TableRow key={position.id}>
                                        <TableCell align="center">
                                            <Typography fontSize={16}>{position.name}, ч</Typography>
                                        </TableCell>

                                        {
                                            dateRange.map(date => date.format(dateFormat)).map(date => {
                                                console.log(position.id + '/' + date)
                                                return (
                                                    <TableCell align="center" sx={{ borderLeft }} key={date}>
                                                        <Typography fontSize={16}>{positionDateAmounts.get(position.id + '/' + date) || '—'}</Typography>
                                                    </TableCell>
                                                )
                                            })
                                        }

                                        <TableCell align="center"sx={{ borderLeft }}>
                                            <Typography fontSize={16}>{totalPositionAmounts.get(position.id) || '—'}</Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                        <TableRow sx={{ fontWeight: 'bold' }}>
                            <TableCell align="center">
                                <Typography fontSize={18} fontWeight="600">Итого, ч</Typography>
                            </TableCell>

                            {
                                dateRange.map(date => date.format(dateFormat)).map(date => {
                                    return (
                                        <TableCell align="center" sx={{ borderLeft }} key={date}>
                                            <Typography fontSize={18} fontWeight="600">{totalDateAmounts.get(date) || '—'}</Typography>
                                        </TableCell>
                                    )
                                })
                            }

                            <TableCell align="center"sx={{ borderLeft }}>
                                <Typography fontSize={18} fontWeight="600">{totalAmount}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}

export default PositionAmountsPerDay;