import { FC, useState } from 'react';
import { Box, Button, Stack, TextField } from '@mui/material';
import { IContractCreate } from '../../API/dto/contracts.dto';

interface IProps {
    objectId: string,
    onSubmit: (payload: IContractCreate) => void
}

const ObjectContractsNew: FC<IProps> = ({ objectId, onSubmit }) => {
    const [name, setName] = useState('');
    const [contractNumber, setContractNumber] = useState('');
    const [counterParty, setCounterParty] = useState('');
    const [region, setRegion] = useState('');
    const [address, setAddress] = useState('');
    const [costForCounterpartyFixed, setFixedCost] = useState<number>(0);

    const handleSubmit = () => onSubmit({ objectId, name, contractNumber, counterParty, region, address, costForCounterpartyFixed })

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Stack direction="row" flexWrap="wrap" gap="10px">
                <TextField
                    type="text"
                    variant="filled"
                    required={true}
                    label='Название'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    type="text"
                    variant="filled"
                    required={true}
                    label='Подразделение'
                    value={contractNumber}
                    onChange={(e) => setContractNumber(e.target.value)}
                />
                <TextField
                    type="text"
                    variant="filled"
                    required={true}
                    label='Контрагент'
                    value={counterParty}
                    onChange={(e) => setCounterParty(e.target.value)}
                />
                <TextField
                    type="text"
                    variant="filled"
                    required={true}
                    label='Регион'
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                />
                <TextField
                    type="text"
                    variant="filled"
                    required={true}
                    label='Адрес'
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
                <TextField
                    type="text"
                    variant="filled"
                    label='Фиксированная стоимость'
                    value={costForCounterpartyFixed}
                    onChange={(e) => setFixedCost(Number(e.target.value))}
                />
            </Stack>
            <Button sx={{ marginTop: '20px' }} 
                variant="outlined" 
                size="large" 
                onClick={handleSubmit}
                disabled={!(name && contractNumber && counterParty && region && address)}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default ObjectContractsNew;