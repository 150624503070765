import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { IContract } from '../../API/dto/contracts.dto';
import { IEmployee } from '../../API/dto/employees.dto';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import { IContractEmployee } from '../../API/interfaces/contractEmployees';
import contractEmployeesService from '../../API/services/contractEmployees.service';
import contractsService from '../../API/services/contracts.service';
import employeesService from '../../API/services/employees.service';
import usePermissions from '../../hooks/usePermissions';
import ContractEmployeesItem from './ContractEmployeesItem';
import ContractEmployeesNew from './ContractEmployeesNew';

const ContractEmployees: FC = () => {
    const { contractId } = useParams();
    const [contract, setContract] = useState<IContract>();
    const [contractEmployees, setContractEmployees] = useState<IContractEmployee[]>([]);
    const [employees, setEmployees] = useState<IEmployee[]>([]);
    const [showForm, setShowForm] = useState(false);

    const hasPermission = usePermissions();
    if(
        !hasPermission(GroupPermissionEnum.ObjectRead) 
        || 
        !hasPermission(GroupPermissionEnum.ContractRead)
        || 
        !hasPermission(GroupPermissionEnum.EmployeeRead)
        ||
        !hasPermission(GroupPermissionEnum.ContractsOnEmployeesRead)
    ){
        return <Navigate to="/" replace />
    }
    const searchContract = () => {
        contractsService.searchContracts({ id: contractId })
            .then(res => res.items[0])
            .then(setContract)
            .catch(console.log);
    }

    const searchContractEmployees = () => {
        contractEmployeesService.search({ contractId })
            .then(res => res.items)
            .then(setContractEmployees)
            .catch(console.log);
    }

    const createContractEmployee = (employeeId: string) => {
        contractId && contractEmployeesService.create({ contractId, employeeId })
            .then(searchContractEmployees)
            .catch(console.log);
    }

    const updateContractEmployee = (id: string, employeeId: string) => {
        contractEmployeesService.update(id, { employeeId })
            .then(searchContractEmployees) 
            .catch(console.log);
    }

    const deleteContractEmployee = (id: string) => {
        contractEmployeesService.delete(id)
            .then(searchContractEmployees)
            .catch(console.log);
    }

    useEffect(() => {
        searchContract();
        searchContractEmployees();
    },[])

    useEffect(() => {
        employeesService.searchEmployees()
            .then(res => res.items)
            .then(setEmployees)
            .catch(console.log);
    }, [])

    if(!contract) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 450  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500  }}/>
        </Container>
    )

    const asignedEmployees = contractEmployees.map(({ employeeId }) => employeeId);
    const availableEmployees = employees.filter(employee => !asignedEmployees.includes(employee.id));

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Link to="/objects"><Typography fontSize={18}>Объекты</Typography></Link>
                <Link to={`/objects/${contract.objectId}/contracts`}><Typography fontSize={18}>Подразделения</Typography></Link>
                <Typography color="black" fontSize={18}>Сотрудники подразделения &quot;{contract.name}&quot;</Typography>
            </Breadcrumbs>

            {
                hasPermission(GroupPermissionEnum.ContractsOnEmployeesEdit) && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v=>!v)}>
                            { showForm ? 'Закрыть' : 'Добавить сотрудника' }
                        </Button>
                        { showForm && <ContractEmployeesNew employees={availableEmployees} onCreate={createContractEmployee} />}
                    </Box>
                )
            }


            {
                contractEmployees.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 500, padding: '20px', }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ minWidth: 250 }}>Сотрудник</TableCell>
                                    {
                                        hasPermission(GroupPermissionEnum.ContractsOnEmployeesEdit) && (
                                            <TableCell align="center" sx={{ minWidth: 120, width: 120 }}></TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    contractEmployees.map(contractEmployee => (
                                        <ContractEmployeesItem 
                                            contractEmployee={contractEmployee}
                                            employees={availableEmployees}
                                            canEdit={hasPermission(GroupPermissionEnum.ContractsOnEmployeesEdit)}
                                            onUpdate={employeeId => updateContractEmployee(contractEmployee.id, employeeId)}
                                            onDelete={() => deleteContractEmployee(contractEmployee.id)}
                                            key={contractEmployee.id}
                                        />
                                    ) )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default ContractEmployees;