import { FC, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import ServiceTypesNew from './ServiceTypesNew';
import { IServiceTypeCreate, IServiceType, IServiceTypeUpdate } from '../../API/dto/serviceTypes.dto';
import ServiceTypesItem from './ServiceTypesItem';
import serviceTypesService from '../../API/services/serviceTypes.service';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import usePermissions from '../../hooks/usePermissions';

const ServiceTypes: FC = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [serviceTypes, setServiceTypes] = useState<IServiceType[]>();
    const [showForm, setShowForm] = useState(false);

    const hasPermission = usePermissions();
    if(!hasPermission(GroupPermissionEnum.ServiceTypeRead)){
        return <Navigate to="/" replace />
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const searchServiceTypes = () => {
        serviceTypesService.search({ limit: rowsPerPage, offset: page * rowsPerPage })
            .then(res => {
                setServiceTypes(res.items); 
                setCount(res.count);
            })
            .catch(console.log);
    }

    const createServiceType = (payload: IServiceTypeCreate) => {
        serviceTypesService.create(payload)
            .then(searchServiceTypes)
            .catch(console.log);
    }

    const updateServiceType = (id: string, payload: IServiceTypeUpdate) => {
        serviceTypesService.update(id, payload)
            .then(searchServiceTypes)
            .catch(console.log);
    }

    const deleteServiceType = (id: string) => {
        serviceTypesService.delete(id)
            .then(searchServiceTypes)
            .catch(console.log);
    }

    useEffect(searchServiceTypes, [page, rowsPerPage]);

    if(!serviceTypes) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500  }}/>
        </Container>
    )

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Типы услуг</Typography>
            </Breadcrumbs>

            <Box sx={{ marginTop: '40px' }}>
                <Button variant="contained" size="large" onClick={() => setShowForm(v=>!v)}>
                    { showForm ? 'Закрыть' : 'Добавить тип' }
                </Button>
                { showForm && <ServiceTypesNew onCreate={createServiceType} /> }
            </Box>
            {
                serviceTypes.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 500 , padding: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Название</TableCell>
                                    {
                                        hasPermission(GroupPermissionEnum.ServiceTypeEdit) && <TableCell width="120px"></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    serviceTypes.map(type => (
                                        <ServiceTypesItem
                                            type={type}
                                            canEdit={hasPermission(GroupPermissionEnum.ServiceTypeEdit)}
                                            onUpdate={payload => updateServiceType(type.id, payload)}
                                            onDelete={() => deleteServiceType(type.id)}
                                            key={type.id}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                        <TablePagination
                            count={count}
                            page={page}
                            onPageChange={(_, value) => setPage(value)}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            component="div"
                        />
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default ServiceTypes;