import {  IconButton, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import { IEmployeeGroup } from '../../API/dto/employeeGroups.dto';

interface IProps {
    groupEmployee: IEmployeeGroup,
    deleteGroupEmployee: (id: string) => void
}

const GroupEmployeesItem: FC<IProps> = ({ groupEmployee, deleteGroupEmployee }) => {
    const hasPermission = usePermissions();

    const handleDelete = () => deleteGroupEmployee(groupEmployee.id);

    return (
        <TableRow>
            <TableCell align="center">{groupEmployee.employee?.lastName} {groupEmployee.employee?.firstName}</TableCell>
            {
                hasPermission(GroupPermissionEnum.EmployeesOnGroupsEdit) && (
                    <TableCell align="center">
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default GroupEmployeesItem;