import { Button, Checkbox, IconButton, MenuItem, Select, TableCell, TableRow, TextField } from '@mui/material';
import { FC, useState, useEffect, useMemo } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IEmployee, EmployeeStatusEnum, IEmployeeUpdate } from '../../API/dto/employees.dto';
import { Link } from 'react-router-dom';
import { IEmployeePosition } from '../../API/dto/employeePositions.dto';
import documentsService from '../../API/services/documents.service';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';

interface IProps {
    employee: IEmployee,
    positions: IEmployeePosition[],
    onUpdate: (payload: IEmployeeUpdate) => void,
    onDelete: () => void,
}

const ServicesItem: FC<IProps> = ({ employee, positions, onUpdate, onDelete }) => {
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>(employee.firstName || '');
    const [middleName, setMiddleName] = useState<string>(employee.middleName || '');
    const [lastName, setLastName] = useState<string>(employee.lastName || '');

    const [citizenship, setСitizenship] = useState<string>(employee.citizenship || '');
    const [email, setEmail] = useState<string>(employee.email || '');
    const [phoneNumber, setPhoneNumber] = useState<string>(employee.phoneNumber || '');

    const [canAuth, setAuthAbility] = useState<boolean>(employee.canAuth || false);

    const [status, setStatus] = useState<EmployeeStatusEnum>(employee.status);
    const [position, setPosition] = useState<{id?: string}>({ id: employee.positionId});

    const [EmployeeDocuments, setEmployeeDocuments] = useState<number>(0);

    const hasPermission = usePermissions();

    const searchDocuments = () => {
        documentsService.search({ employeeId: employee.id })
        .then((r) => {setEmployeeDocuments(r.count)})
        .catch((err) => console.log(err))
    }

    useEffect(searchDocuments,  []);

    const objectNames = useMemo(() => {
        const names: string[] = [];
        employee.contracts.map(employeeContract => {
            const objectName = employeeContract.contract?.object?.name;
            if (objectName && !names.includes(objectName)) {
                names.push(objectName);
            }
        })
        return names;
    }, [])


    const handleUpdate = () => {
        onUpdate({ firstName, middleName, lastName, citizenship, email, phoneNumber, status, position, canAuth });
        setEditMode(false);
    }

    if(hasPermission(GroupPermissionEnum.EmployeeEdit) && isEditMode) return (
        <TableRow>
            <TableCell align="center">
                <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    required={true}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    required={true}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    value={middleName}
                    onChange={(e) => setMiddleName(e.target.value)}
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    required={true}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    value={citizenship}
                    onChange={(e) => setСitizenship(e.target.value)}
                />
            </TableCell>
            <TableCell align="center">
                <Select size="small" fullWidth value={position.id} onChange={e => setPosition({ id: e.target.value })}>
                    {
                        positions.map(position => (
                            <MenuItem value={position.id} key={position.id}>
                                {position.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </TableCell>
            <TableCell align="center">
                <Select required size="small" fullWidth value={status} onChange={e => setStatus(e.target.value as EmployeeStatusEnum)} >
                    {
                        Object.values(EmployeeStatusEnum).map((status) => (
                            <MenuItem value={status} key={status}>
                                {status}
                            </MenuItem>
                        ))
                    }
                </Select>
            </TableCell>
            <TableCell align="center">
                <Checkbox checked={canAuth} onChange={()=>setAuthAbility(!canAuth)} />
            </TableCell>
            {
                hasPermission(GroupPermissionEnum.ContractsOnEmployeesRead) && (
                    <TableCell align="center">
                        {objectNames.map((objectName: string, i: number) => (
                            <p key={i}>{objectName}</p>
                        ))}   
                    </TableCell>
                )
            }
            {
                hasPermission(GroupPermissionEnum.EmployeesOnGroupsRead) && (
                    <TableCell align="center">
                        <Link to={`/employees/${employee.id}/groups`}>
                            <Button variant="outlined">{ employee.groups.length || 0}</Button>
                        </Link>
                    </TableCell>
                )
            }
            {
                hasPermission(GroupPermissionEnum.EmployeesOnServicesRead) && (
                    <TableCell align="center">
                        <Link to={`/employees/${employee.id}/services`}>
                            <Button variant="outlined">{employee.services?.length || 0}</Button>
                        </Link>
                    </TableCell>
                )
            }
            {
                hasPermission(GroupPermissionEnum.DocumentRead) && (
                    <TableCell align="center">
                        <Link to={`/employees/${employee.id}/documents`}>
                            <Button variant="outlined">{EmployeeDocuments}</Button>
                        </Link>
                    </TableCell>
                )
            }
            <TableCell align="center">
                <IconButton onClick={() => setEditMode(false)}>
                    <CancelIcon/>
                </IconButton>
                <IconButton onClick={handleUpdate} disabled={!(firstName && lastName && phoneNumber && status)}>
                    <CheckCircleIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    )
    
    return (
        <TableRow>
            <TableCell align="center">{employee.lastName}</TableCell>
            <TableCell align="center">{employee.firstName}</TableCell>
            <TableCell align="center">{employee.middleName}</TableCell>
            <TableCell align="center">{employee.phoneNumber}</TableCell>
            <TableCell align="center">{employee.email}</TableCell>
            <TableCell align="center">{employee.citizenship}</TableCell>
            <TableCell align="center">{employee.position?.name}</TableCell>
            <TableCell align="center">{employee.status}</TableCell>
            <TableCell align="center">
                <Checkbox checked={employee.canAuth} disabled={true} />
            </TableCell>
            {
                hasPermission(GroupPermissionEnum.ContractsOnEmployeesRead) && (
                    <TableCell align="center">
                        {objectNames.map((objectName: string, i: number) => (
                            <p key={i}>{objectName}</p>
                        ))}   
                    </TableCell>
                )
            }
            {
                hasPermission(GroupPermissionEnum.EmployeesOnGroupsRead) && (
                    <TableCell align="center">
                        <Link to={`/employees/${employee.id}/groups`}>
                            <Button variant="outlined">{ employee.groups.length || 0}</Button>
                        </Link>
                    </TableCell>
                )
            }
            {
                hasPermission(GroupPermissionEnum.EmployeesOnServicesRead) && (
                    <TableCell align="center">
                        <Link to={`/employees/${employee.id}/services`}>
                            <Button variant="outlined">{employee.services?.length || 0}</Button>
                        </Link>
                    </TableCell>
                )
            }
            {
                hasPermission(GroupPermissionEnum.DocumentRead) && (
                    <TableCell align="center">
                        <Link to={`/employees/${employee.id}/documents`}>
                            <Button variant="outlined">{EmployeeDocuments}</Button>
                        </Link>
                    </TableCell>
                )
            }
            {
                hasPermission(GroupPermissionEnum.EmployeeEdit) && (
                    <TableCell align="center" sx={{minWidth: '120px'}}>
                        <IconButton onClick={() => setEditMode(true)}>
                            <EditIcon/>
                        </IconButton>
                        <IconButton onClick={onDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default ServicesItem;