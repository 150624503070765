import { FC, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import ObjectsNew from './ObjectsNew';
import { IObjectCreate, IObject, IObjectUpdate } from '../../API/dto/objects.dto';
import ObjectsItem from './ObjectsItem';
import objectsService from '../../API/services/objects.service';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import usePermissions from '../../hooks/usePermissions';

const Objects: FC = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [objects, setObjects] = useState<IObject[]>();
    const [showForm, setShowForm] = useState(false);

    const hasPermission = usePermissions();
    if(!hasPermission(GroupPermissionEnum.ObjectRead)){
        return <Navigate to="/" replace />
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const searchObjects = () => {
        objectsService.searchObjects({ limit: rowsPerPage, offset: page * rowsPerPage })
            .then(res => {
                setObjects(res.items); 
                setCount(res.count);
                setShowForm(false);
            })
            .catch(console.log);
    }

    const createObject = (payload: IObjectCreate) => {
        objectsService.createObject(payload)
            .then(searchObjects)
            .catch(console.log);
    }

    const updateObject = (id: string, payload: IObjectUpdate) => {
        objectsService.updateObject(id, payload)
            .then(searchObjects)
            .catch(console.log);
    }

    const deleteObject = (id: string) => {
        objectsService.deleteObject(id)
            .then(searchObjects)
            .catch(console.log);
    }

    useEffect(searchObjects, [page, rowsPerPage]);

    if(!objects) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 500  }}/>
        </Container>
    )

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Объекты</Typography>
            </Breadcrumbs>

            {
                hasPermission(GroupPermissionEnum.ObjectEdit) && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v=>!v)}>
                            { showForm ? 'Закрыть' : 'Добавить объект' }
                        </Button>
                        { showForm && <ObjectsNew onCreate={createObject} /> }
                    </Box>
                )
            }

            {
                objects.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 500, padding: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" >Название</TableCell>
                                    {
                                        hasPermission(GroupPermissionEnum.ContractRead) && <TableCell align="center" width="120">Подразделения</TableCell>
                                    }
                                    {
                                        hasPermission(GroupPermissionEnum.ObjectEdit) && <TableCell width="120px"></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    objects.map(object => (
                                        <ObjectsItem
                                            object={object}
                                            onUpdate={payload => updateObject(object.id, payload)}
                                            onDelete={() => deleteObject(object.id)}
                                            key={object.id}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                        <TablePagination
                            count={count}
                            page={page}
                            onPageChange={(_, value) => setPage(value)}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            component="div"
                        />
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default Objects;